import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import logo from "../assets/peerspocket_logo.png";
import { SignUp } from "@clerk/clerk-react";

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  return (
    <Box>
      <Box
        width="100%"
        backgroundColor={theme.palette.background.alt}
        p="1rem 6%"
        textAlign="center"
      >
        <img
          src={logo}
          alt="Company Logo"
          style={{ width: "18rem", height: "auto" }}
        />
      </Box>
      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background.alt}
        style={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem" }}>
          Sign up
        </Typography>
        <SignUp signInUrl="/login" />
      </Box>
    </Box>
  );
};

export default LoginPage;
