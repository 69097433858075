import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const AssetLiabilityWidget = ({ data }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        borderRadius: 2,
        padding: 2,
        width: "100%",
        color: theme.palette.text.primary,
      }}
    >
      {data.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1.25rem 1rem",
            borderRadius: 1.5,
            backgroundColor: theme.palette.background.alt,
            marginBottom: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ marginRight: 2, color: theme.palette.text.secondary }}>
              {item.icon}
            </Box>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "1.1rem",
                color: theme.palette.text.secondary,
              }}
            >
              {item.name}
            </Typography>
          </Box>
          <Typography
            sx={{
              color:
                item.value >= 0
                  ? theme.palette.success.main
                  : theme.palette.error.main,
              fontSize: "1.1rem",
            }}
          >
            {item.value >= 0
              ? `$${item.value.toLocaleString()}`
              : `-$${Math.abs(item.value).toLocaleString()}`}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default AssetLiabilityWidget;
