import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import "chart.js/auto";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { randomDelay } from "../../utils/randomDelay.js";
const countryAdjectives = {
  Canada: "Canadians",
};

const mockPeerSalaries = [
  45000, 55000, 65000, 75000, 85000, 95000, 105000, 115000, 125000, 135000,
  145000, 155000,
];

const SalaryComparisonWidgetLandingPage = ({
  country = "Canada",
  userIncome,
  updateData,
  setUpdateData,
}) => {
  const theme = useTheme();
  const [selectedCountry, setSelectedCountry] = useState(country);
  const [percentile, setPercentile] = useState(0);
  const [loading, setLoading] = useState(false);

  // Function to generate percentile labels
  const generateLabels = (data) => {
    const sortedData = [...data].sort((a, b) => a - b);
    return sortedData.map((_, index) => `${index + 1}`);
  };

  // Filter and sort peer salaries
  const sortedPeerSalaries = mockPeerSalaries
    .filter((salary) => salary !== null)
    .sort((a, b) => a - b);

  useEffect(() => {
    if (updateData) {
      setLoading(true);
      const calculatePercentile = (income) => {
        const index = sortedPeerSalaries.findIndex(
          (salary) => salary >= income,
        );
        return ((index / sortedPeerSalaries.length) * 100).toFixed(2);
      };

      setTimeout(
        () => {
          setPercentile(calculatePercentile(userIncome));
          setLoading(false);
          setUpdateData(false); // Reset updateData after updating the data
        },
        randomDelay(3000, 4200),
      );
    }
  }, [updateData, userIncome, sortedPeerSalaries, setUpdateData]);

  const roundedPercentile = Math.round(percentile);

  // Prepare chart data
  const userIndex = sortedPeerSalaries.findIndex(
    (salary) => salary >= userIncome,
  );
  const data = {
    labels: generateLabels(sortedPeerSalaries),
    datasets: [
      {
        label: "Income",
        data: sortedPeerSalaries,
        backgroundColor: sortedPeerSalaries.map((_, index) =>
          index === userIndex ? "#D6D273" : "#1B3938",
        ),
        borderRadius: 5,
      },
    ],
  };

  // Chart options
  const options = {
    scales: {
      x: { display: false },
      y: { display: false },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `$${Math.round(context.raw).toLocaleString()}`;
          },
          title: function (context) {
            if (context[0].dataIndex === userIndex) {
              return "User";
            }
            return "";
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  // Text segments for displaying percentile information
  const textSegments = [
    {
      text: "Your income of",
      bold: true,
      color: theme.palette.text.secondary,
    },
    {
      text: `~$${Math.round(userIncome || 0).toLocaleString()}`,
      bold: true,
      color: "#D6D273",
    },
    {
      text: "ranks you at the",
      bold: true,
      color: theme.palette.text.secondary,
    },
    {
      text: `${roundedPercentile}th`,
      bold: true,
      color: "#D6D273",
    },
    {
      text: "percentile compared to  your",
      bold: true,
      color: theme.palette.text.secondary,
    },
    {
      text: "peers",
      bold: true,
      color: "#D6D273",
    },
  ];

  // Handle country selection change
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.alt,
        borderRadius: 2,
        padding: 3,
        width: "100%",
        maxWidth: "400px", // Ensures the widget is square
        height: "380px", // Ensures the widget is square
        color: theme.palette.text.primary,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginTop: 4,
      }}
    >
      {loading ? (
        <Box>
          <Skeleton variant="text" sx={{ fontSize: "1.10rem", width: "70%" }} />
          <Skeleton
            variant="rectangular"
            sx={{ marginTop: 5, height: "160px", width: "100%" }}
          />
          <Skeleton variant="text" sx={{ marginTop: 5, width: "50%" }} />
          <Skeleton variant="text" sx={{ width: "60%" }} />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: "medium", fontSize: "1.10rem" }}>
              Salary Comparison
            </Typography>
            {/* <Select
              value="Canada"
              title="Canada"
              onChange={(e) => setSelectedCountry(e.target.value)}
              disabled
              sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: 1,
                padding: "0.25rem 1rem",
                color: theme.palette.text.primary,
              }}
            >
              <MenuItem value="Canada">Canada</MenuItem>
            </Select> */}
          </Box>
          <Box sx={{ marginTop: 5, position: "relative", height: "160px" }}>
            <Bar data={data} options={options} />
            {userIndex !== -1 && !loading && (
              <Typography
                sx={{
                  position: "absolute",
                  left: `${(userIndex / sortedPeerSalaries.length) * 100}%`,
                  bottom: "100%",
                  transform: "translateX(-50%)",
                  color: "#D6D273",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginLeft: 2,
                }}
              >
                You
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              textAlign: "center",
              color: theme.palette.text.secondary,
              marginTop: 2,
            }}
          >
            {textSegments.map((segment, index) => (
              <Typography
                key={index}
                component="span"
                sx={{
                  fontWeight: segment.bold ? "bold" : "normal",
                  marginRight: 0.5,
                  fontSize: "0.9rem",
                  color: segment.color,
                }}
              >
                {segment.text}
              </Typography>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default SalaryComparisonWidgetLandingPage;
