import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Slider,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { postComparisonData } from "../Api";
import { professions, ProvinceMenuItems, StateMenuItems } from "../model";
import "./ComparisonSection.css";
import NetworthWidgetLandingPage from "./widgets/NetworthComparisonWidgetLandingPage";
import SalaryComparisonWidgetLandingPage from "./widgets/SalaryComparisonWidgetLandingPage";

const DropdownInput = ({ label, value, onChange, menuItems }) => (
  <Box className="dropdown-container">
    <FormControl variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        style={{ width: "200px" }}
        label={label}
      >
        {menuItems}
      </Select>
    </FormControl>
  </Box>
);

const SliderInput = ({
  label,
  value,
  onChange,
  onChangeCommitted,
  min,
  max,
  step,
  marks,
}) => (
  <Box className="slider-container">
    <Typography className="slider-label">
      {label}: {value.toLocaleString()}
    </Typography>
    <Slider
      value={value}
      onChange={onChange}
      onChangeCommitted={onChangeCommitted}
      min={min}
      max={max}
      step={step}
      marks={marks}
      valueLabelDisplay="auto"
    />
  </Box>
);

const BubbleSelector = ({ label, value, onChange, options }) => (
  <Box className="bubble-container">
    <Typography className="slider-label">{label}</Typography>
    <Box className="bubble-selector">
      {options.map((option) => (
        <Chip
          key={option}
          label={option}
          color={value === option ? "primary" : "default"}
          onClick={() => onChange(option)}
          style={{ margin: "5px" }}
        />
      ))}
    </Box>
  </Box>
);

const stepConfig = [
  {
    label: "Select your country",
    component: (state, handleChange) => (
      <DropdownInput
        label="Country"
        value={state.country}
        onChange={handleChange("country")}
        menuItems={[
          <MenuItem value="USA" key="USA">
            USA
          </MenuItem>,
          <MenuItem value="Canada" key="Canada">
            Canada
          </MenuItem>,
        ]}
      />
    ),
  },
  {
    label: "Select your state/province",
    component: (state, handleChange) => (
      <DropdownInput
        label="State/Province"
        value={state.stateProvince}
        onChange={handleChange("stateProvince")}
        menuItems={state.country === "USA" ? StateMenuItems : ProvinceMenuItems}
      />
    ),
  },
  {
    label: "",
    component: (state, handleChange) => (
      <SliderInput
        label="Age"
        value={state.age}
        onChange={(_, value) => handleChange("age")(value)}
        onChangeCommitted={(_, value) => handleChange("age")(value)}
        min={14}
        max={55}
        marks={[{ value: 55, label: "55+" }]}
      />
    ),
  },
  {
    label: "",
    component: (state, handleChange) => (
      <BubbleSelector
        label="Profession"
        value={state.profession}
        onChange={handleChange("profession")}
        options={professions}
      />
    ),
  },
  {
    label: "",
    component: (state, handleChange) => (
      <SliderInput
        label="Income"
        value={state.income}
        onChange={(_, value) => handleChange("income")(value)}
        onChangeCommitted={(_, value) => handleChange("income")(value)}
        min={0}
        max={500000}
        step={5000}
        marks={[{ value: 500000, label: "500,000+" }]}
      />
    ),
  },
  {
    label: "",
    component: (state, handleChange) => (
      <SliderInput
        label="Net Worth"
        value={state.netWorth}
        onChange={(_, value) => handleChange("netWorth")(value)}
        onChangeCommitted={(_, value) => handleChange("netWorth")(value)}
        min={-500000}
        max={500000}
        step={10000}
        marks={[
          { value: 500000, label: "500,000+" },
          { value: -500000, label: "-500,000" },
        ]}
      />
    ),
  },
];

const StepContent = ({ step, state, handleChange, handleComparison }) => {
  if (step < 0 || step >= stepConfig.length) {
    return null;
  }

  return (
    <div className="step-content">
      <Typography variant="h6" gutterBottom>
        {stepConfig[step].label}
      </Typography>
      {stepConfig[step].component(state, handleChange, handleComparison)}
    </div>
  );
};

const ComparisonSection = ({
  state,
  setState,
  handleChange,
  handleComparison,
}) => {
  const [step, setStep] = useState(0);
  const [showWidgets, setShowWidgets] = useState(false);
  const stepRef = useRef(null);
  const widgetRef = useRef(null);

  const handleStepChange = (direction) => {
    setStep((prevStep) => {
      const newStep = prevStep + direction;
      if (newStep < 0 || newStep > stepConfig.length) return prevStep;
      if (direction === -1 && newStep < stepConfig.length)
        setShowWidgets(false);
      return newStep;
    });
  };

  const handleCompare = async () => {
    try {
      await postComparisonData(state); // Post the state data to the server
      setShowWidgets(true);
      widgetRef.current.scrollIntoView({ behavior: "smooth" });
      handleComparison();
    } catch (error) {
      console.error("Error posting comparison data:", error);
    }
  };

  return (
    <section className="salary-comparison-section">
      <div className="salary-comparison-wrapper">
        <h2 className="comparison-heading fade-in-slide fade-in-slide-delay-1">
          Let&#39;s Check Your Financial Rank
        </h2>
        <p className="comparison-subtitle fade-in-slide fade-in-slide-delay-2">
          Find Out Where You Stand Financially
        </p>
        <Typography
          variant="subtitle1"
          align="center"
          gutterBottom
          className="typography-subtitle"
        >
          <LinearProgress
            variant="determinate"
            value={(step / stepConfig.length) * 100}
            style={{ height: "8px", borderRadius: "5px" }}
            className="progress-bar"
          />
          <div ref={stepRef}>
            <StepContent
              step={step}
              state={state}
              handleChange={handleChange}
              handleComparison={handleCompare}
            />
            <Box className="button-container">
              {step >= 0 && (
                <Button
                  onClick={() => handleStepChange(-1)}
                  className="arrow-left"
                  style={{
                    opacity: step === 0 ? 0 : 1,
                    pointerEvents: step === 0 ? "none" : "auto",
                  }}
                >
                  Back
                </Button>
              )}
              {step < stepConfig.length && (
                <Button
                  onClick={() => handleStepChange(1)}
                  color="primary"
                  className="arrow-right"
                >
                  Next
                </Button>
              )}
            </Box>
          </div>
        </Typography>
        {step === stepConfig.length && (
          <Box className="compare-button-container">
            <Button className="compare-button" onClick={handleCompare}>
              Compare Me
            </Button>
          </Box>
        )}
        <div
          ref={widgetRef}
          className={`comparison-widgets-wrapper ${showWidgets ? "visible" : ""}`}
        >
          {showWidgets && (
            <>
              <SalaryComparisonWidgetLandingPage
                userIncome={state.income}
                updateData={state.shouldUpdateData}
                setUpdateData={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    shouldUpdateData: value,
                  }))
                }
              />
              <NetworthWidgetLandingPage
                userNetworth={state.netWorth}
                updateData={state.shouldUpdateData}
                setUpdateData={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    shouldUpdateData: value,
                  }))
                }
                province={state.province}
              />
              <Typography
                variant="caption"
                align="center"
                display="block"
                className="disclaimer"
              >
                *This data is for educational purposes only.
              </Typography>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ComparisonSection;
