import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import debounce from "lodash.debounce";
import { useEffect, useMemo, useState } from "react";
import { usePostQuery } from "../../utils/queryParamUtils";
import FlexBetween from "../UI/FlexBetween";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [query, setPostQuery] = usePostQuery();
  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;

  const debouncedSearch = useMemo(() => {
    return debounce((term) => {
      setPostQuery(term);
    }, 300); // Adjust the delay as needed
  }, [setPostQuery]);

  const handleChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const handleIconClick = () => {
    if (searchTerm) {
      setSearchTerm(""); // Reset the search term
      setPostQuery(undefined);
    }
  };

  useEffect(() => {}, [searchTerm]);

  return (
    <FlexBetween
      style={{
        backgroundColor: neutralLight,
        borderRadius: "15px",
        gap: "1rem",
        padding: "0.1rem 1.5rem",
        flexGrow: 0.5,
        justifyContent: "left",
      }}
    >
      <IconButton onClick={handleIconClick}>
        {searchTerm ? <ArrowBackIosNewIcon /> : <SearchIcon />}
      </IconButton>
      <InputBase
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
        style={{ width: "100%" }}
      />
    </FlexBetween>
  );
};

export default SearchBar;
