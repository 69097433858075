import PostFeed from "./PostFeed";
import { PostFilter } from "./PostFilter";

const RecommendedPosts = () => {
  return (
    <>
      <PostFilter />
      <PostFeed />
    </>
  );
};

export default RecommendedPosts;
