import { useUser as useUserClerk } from "@clerk/clerk-react";
import React, { createContext, useContext, useMemo } from "react";
import { useAsyncRetry } from "react-use";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { user, isLoaded, isSignedIn } = useUserClerk();

  const {
    value: userData,
    loading,
    error,
    retry,
  } = useAsyncRetry(async () => {
    if (!isLoaded || !isSignedIn) return null;
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/${user.id}`,
    );
    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }
    return await response.json();
  }, [isLoaded, isSignedIn, user]);

  const value = useMemo(
    () => ({
      user: isSignedIn ? { ...user, ...userData } : null,
      isLoaded: isLoaded && !loading,
      retry,
    }),
    [user, userData, isLoaded, loading, error, isSignedIn],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
