import { UserProfile } from "@clerk/clerk-react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useUser } from "../context/UserContext";
import NavBar from "./NavBar";

const validationSchema = yup.object().shape({
  ageRange: yup.string().required("required"),
  country: yup.string().required("required"),
  profession: yup.string().required("required"),
  typeOfShopper: yup.string().required("required"),
  salaryRanges: yup.string().optional(),
  userBio: yup.string().optional().max(100),
});

const ageRanges = [
  "16-18",
  "19-24",
  "25-29",
  "30-34",
  "35-39",
  "40-44",
  "45-49",
  "50-54",
  "55-59",
  "60+",
];

const countries = ["Canada", "United States"];

const professions = [
  "Healthcare",
  "Education",
  "Engineering",
  "Technology",
  "Business",
  "Law",
  "Creative Arts",
  "Social Services",
  "Agriculture",
  "Skilled Trades",
  "Science",
  "Media",
  "Hospitality",
  "Transportation",
  "Public Service",
  "Finance",
  "Sales",
  "Marketing",
  "Consulting",
  "Military",
];

const shopperTypes = [
  "Window Shopper",
  "Bargain Hunter",
  "Impulse Buyer",
  "Researcher",
  "Loyal Customer",
  "Trendsetter",
  "Practical Shopper",
];

const salaryRanges = [
  "Less than $20,000",
  "$20,000 - $39,999",
  "$40,000 - $59,999",
  "$60,000 - $79,999",
  "$80,000 - $99,999",
  "$100,000 - $119,999",
  "$120,000 - $139,999",
  "$140,000 - $159,999",
  "$160,000 - $179,999",
  "$180,000 - $199,999",
  "$200,000 - $219,999",
  "$220,000 - $239,999",
  "$240,000 - $259,999",
  "$260,000 - $279,999",
  "$280,000 - $299,999",
  "$300,000 - $319,999",
  "$320,000 - $339,999",
  "$340,000 - $359,999",
  "$360,000 - $379,999",
  "$380,000 - $399,999",
  "$400,000 - $419,999",
  "$420,000 - $439,999",
  "$440,000 - $459,999",
  "$460,000 - $479,999",
  "$480,000 - $499,999",
  "$500,000 or more",
];

const UpdateProfile = ({ password }) => {
  const [clicked, setClicked] = useState(false);
  const [error, setError] = useState(false);
  const { user, retry } = useUser();
  const { userID } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const initialValues = {
    ageRange: user.ageRange,
    country: user.country,
    profession: user.profession,
    typeOfShopper: user.typeOfShopper,
    salaryRanges: user.salaryRanges,
    userName: user.username,
    password: "",
    userBio: user.userBio,
  };
  const handleFormSubmit = async (values, onSubmitProps) => {
    setClicked(true);
    const formData = { ...values };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/${userID}/update`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        },
      );
      const data = await response.json();
      retry();
      if (data.msg === "Incorrect Password") {
        setError(true);
        setClicked(false);
        return;
      }
      if (response.ok) {
        navigate("/home");
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error updating user:", error);
      setError(true);
    }
    setClicked(false);
    onSubmitProps.setSubmitting(false);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <NavBar user={user} />
      {password ? (
        <UserProfile />
      ) : (
        <Box
          width={isNonMobileScreens ? "50%" : "95%"}
          p="2rem"
          m="2rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
        >
          <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem" }}>
            Update your profile
          </Typography>

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <React.Fragment>
                    <TextField
                      label="Username"
                      value={values.userName}
                      name="userName"
                      disabled
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      label="Age Range"
                      select
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ageRange}
                      name="ageRange"
                      error={
                        Boolean(touched.ageRange) && Boolean(errors.ageRange)
                      }
                      helperText={touched.ageRange && errors.ageRange}
                      sx={{ gridColumn: "span 4" }}
                    >
                      {ageRanges.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Country"
                      select
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.country}
                      name="country"
                      error={
                        Boolean(touched.country) && Boolean(errors.country)
                      }
                      helperText={touched.country && errors.country}
                      sx={{ gridColumn: "span 4" }}
                    >
                      {countries.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Profession"
                      select
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.profession}
                      name="profession"
                      error={
                        Boolean(touched.profession) &&
                        Boolean(errors.profession)
                      }
                      helperText={touched.profession && errors.profession}
                      sx={{ gridColumn: "span 4" }}
                    >
                      {professions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Type of Shopper"
                      select
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.typeOfShopper}
                      name="typeOfShopper"
                      error={
                        Boolean(touched.typeOfShopper) &&
                        Boolean(errors.typeOfShopper)
                      }
                      helperText={touched.typeOfShopper && errors.typeOfShopper}
                      sx={{ gridColumn: "span 4" }}
                    >
                      {shopperTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Salary Range"
                      select
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.salaryRanges}
                      name="salaryRanges"
                      error={
                        Boolean(touched.salaryRanges) &&
                        Boolean(errors.salaryRanges)
                      }
                      helperText={touched.salaryRanges && errors.salaryRanges}
                      sx={{ gridColumn: "span 4" }}
                    >
                      {salaryRanges.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      label="Profile Bio"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.userBio}
                      name="userBio"
                      error={
                        Boolean(touched.userBio) && Boolean(errors.userBio)
                      }
                      helperText={touched.userBio && errors.userBio}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </React.Fragment>
                </Box>

                {/* ALERT */}
                {error && (
                  <Box sx={{ width: "100%", pt: "2%" }}>
                    <Collapse in={error}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setError(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        sx={{ fontSize: "0.8rem" }}
                      >
                        Unauthorized
                      </Alert>
                    </Collapse>
                  </Box>
                )}

                {/* BUTTONS */}
                <Box>
                  <Button
                    fullWidth
                    type="submit"
                    sx={{
                      m: "2rem 0",
                      p: "1rem",
                      backgroundColor: !clicked
                        ? theme.palette.primary.main
                        : "#808080",
                      color: !clicked
                        ? theme.palette.background.alt
                        : "#101010",
                      "&:hover": {
                        color: !clicked ? theme.palette.primary.main : null,
                        backgroundColor: !clicked ? null : "#808080",
                      },
                      "&:disabled": {
                        color: !clicked
                          ? theme.palette.background.alt
                          : "#101010",
                      },
                    }}
                    disabled={clicked || Object.keys(errors).length !== 0}
                  >
                    {!clicked ? "UPDATE" : "WAIT..."}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      )}
    </Box>
  );
};

export default UpdateProfile;
