import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Hook to manage url parameters
 *
 * @param {string} name - The name of the url parameter
 * @param {string| undefined} defaultValue - The initial value of the url parameter
 *
 * @returns {[string|undefined, function]} - The value of the url parameter and a function to set the value
 */
const useUrlParam = (name, defaultValue) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getValue = useMemo(() => {
    return searchParams.get(name) || defaultValue;
  }, [searchParams, name, defaultValue]);

  const setValue = useCallback(
    (value) => {
      searchParams.delete(name);
      if (value) {
        searchParams.set(name, value);
      }
      setSearchParams(searchParams);
    },
    [searchParams, name, setSearchParams],
  );

  return [getValue, setValue];
};

export default useUrlParam;
