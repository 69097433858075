import { AddCircleOutline } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { usePosts } from "../context/PostsContext";
import { useUser } from "../context/UserContext";
import PostFeed from "./PostFeed";
import PostCreationWidget from "./widgets/PostCreationWidget";

const ProfilePosts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { posts } = usePosts();
  const { user } = useUser();
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const theme = useTheme();
  const iconColor = theme.palette.mode === "dark" ? "white" : "black";

  return (
    <Box className="main-container">
      <PostFeed />
      {posts.length === 0 && (
        <Box textAlign="center" mt={2}>
          <Typography variant="h6" mb={1}>
            Hey {user.username}, looks like you haven&apos;t posted anything
            yet!
          </Typography>
          <IconButton onClick={handleOpenModal} color="primary">
            <AddCircleOutline fontSize="large" sx={{ color: iconColor }} />
          </IconButton>
        </Box>
      )}

      {isModalOpen && (
        <PostCreationWidget open={isModalOpen} onClose={handleCloseModal} />
      )}
    </Box>
  );
};

export default ProfilePosts;
