import { Box, MenuItem, Select, Typography, useTheme } from "@mui/material";
import "chart.js/auto";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { fetchNetWorthHistory } from "../../Api";

const NetworthChartWidget = ({
  userId,
  initialData = null,
  lockTimeWindow = false,
}) => {
  const theme = useTheme();
  const [netWorth, setNetWorth] = useState(0);
  const [timeWindow, setTimeWindow] = useState("1Y");
  const [dataPoints, setDataPoints] = useState([]);
  const [netWorthData, setNetWorthData] = useState([]);
  const [change, setChange] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      if (initialData) {
        setDataPoints(initialData);
        console.log("initialData", initialData);
        const latestNetWorth =
          initialData.length > 0
            ? initialData[initialData.length - 1].netWorth
            : 0;
        setNetWorth(latestNetWorth);
        setNetWorthData(initialData); // Set initial data if provided
      } else {
        try {
          const data = await fetchNetWorthHistory(userId);
          console.log("data", data);
          setDataPoints(data);
          const latestNetWorth =
            data.length > 0 ? data[data.length - 1].netWorth : 0;
          setNetWorth(latestNetWorth);
          setNetWorthData(data); // Set fetched data
        } catch (error) {
          console.error("Failed to fetch net worth history:", error);
        }
      }
    };

    fetchData();
  }, [userId, initialData, setNetWorthData]);

  const formatData = (data, timeWindow) => {
    const formatted = {
      "1M": [],
      "3M": [],
      "8M": [],
      "1Y": [],
    };

    const now = new Date();
    const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
    const threeMonthsAgo = new Date(now.setMonth(now.getMonth() - 2)); // -3 months total
    const eightMonthsAgo = new Date(now.setMonth(now.getMonth() - 5)); // -8 months total
    const oneYearAgo = new Date(now.setFullYear(now.getFullYear() - 1));

    data.forEach((point) => {
      const date = new Date(point.date);
      const time = `${date.getMonth() + 1}/${date.getFullYear()}`;
      const value = point.netWorth;

      formatted["1Y"].push({ time, value });
      if (date >= eightMonthsAgo) {
        formatted["8M"].push({ time, value });
      }
      if (date >= threeMonthsAgo) {
        formatted["3M"].push({ time, value });
      }
      if (date >= oneMonthAgo) {
        formatted["1M"].push({ time, value });
      }
    });

    return formatted;
  };

  const formattedDataPoints = formatData(dataPoints, timeWindow);

  useEffect(() => {
    const values = formattedDataPoints[timeWindow].map((point) => point.value);
    if (values.length > 1) {
      const changeValue = values[values.length - 1] - values[0];
      setChange(changeValue);
    } else {
      setChange(0);
    }
  }, [timeWindow, formattedDataPoints]);

  const labels =
    formattedDataPoints[timeWindow]?.map((point) => point.time) || [];
  const values =
    formattedDataPoints[timeWindow]?.map((point) => point.value) || [];

  const data = {
    labels,
    datasets: [
      {
        label: "Net Worth",
        data: values,
        borderColor: theme.palette.secondary.main,
        backgroundColor: `${theme.palette.secondary.main}33`, // Gradient fill color with transparency
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: true,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  console.log("NetworthChartWidget", dataPoints);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.alt,
        borderRadius: 2,
        padding: 3,
        width: "100%",
        color: theme.palette.text.primary,
        position: "relative",
      }}
    >
      {netWorth === 0 ? (
        <Typography variant="h6" align="center" color="error">
          Your net worth is $0
        </Typography>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 1,
            }}
          >
            <Box>
              <Typography
                style={{ fontWeight: "semi bold", fontSize: "1.75rem" }}
              >
                ${netWorth.toLocaleString()}
              </Typography>
              <Typography
                style={{
                  color:
                    change >= 0
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  fontSize: "1.25rem",
                }}
              >
                {change >= 0 ? `↑` : `↓`} ${Math.abs(change).toLocaleString()}
              </Typography>
            </Box>
            {!lockTimeWindow && (
              <Select
                value={timeWindow}
                onChange={(e) => setTimeWindow(e.target.value)}
                sx={{
                  marginBottom: 2,
                  backgroundColor: theme.palette.background.default,
                  borderRadius: 1,
                  padding: "0.25rem 1rem",
                  color: theme.palette.text.primary,
                }}
              >
                <MenuItem value="1M">1 Month</MenuItem>
                <MenuItem value="3M">3 Months</MenuItem>
                <MenuItem value="8M">8 Months</MenuItem>
                <MenuItem value="1Y">1 Year</MenuItem>
              </Select>
            )}
          </Box>
          <Box sx={{ height: "200px", marginTop: 2, position: "relative" }}>
            <Line data={data} options={options} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default NetworthChartWidget;
