import {
  AccountBalanceWallet,
  AttachMoney,
  MonetizationOn,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  fetchComparisonData,
  fetchIncomeData,
  fetchNetWorthHistory,
  fetchSpendingData,
} from "../../Api";
import { usePostsDispatch } from "../../context/PostsContext";
import { useUser } from "../../context/UserContext";
import NetworthChartWidget from "./NetworthChartWidget";
import SalaryWidget from "./SalaryWidget";
import SpendingChartWidget from "./SpendingChartWidget";

const PostCreationWidget = ({ open, onClose }) => {
  const theme = useTheme();
  const { addPost } = usePostsDispatch();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(dayjs().format("MMMM YYYY"));
  const [netWorthData, setNetWorthData] = useState([]);
  const [monthData, setMonthData] = useState({
    transactions: 0,
    totalExpenses: 0,
    income: 0,
    remaining: 0,
    categories: [],
  });
  const [salaryData, setSalaryData] = useState({
    userIncome: 0,
    percentile: 0,
    peerSalaries: [],
  });
  const { user } = useUser();

  const handlePost = async () => {
    const formData = {
      title,
      description: body,
      ...(selectedWidget === "Spending" && { spendingData: monthData }),
      ...(selectedWidget === "Networth" &&
        netWorthData.length > 0 && { netWorthData }),
      ...(selectedWidget === "Salary" &&
        salaryData.userIncome && { salaryData }),
    };

    try {
      await addPost(formData);
      onClose();
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  const updateSpendingData = async () => {
    const monthYear = dayjs(currentMonth, "MMMM YYYY");
    const year = monthYear.year();
    const month = monthYear.format("MM");
    const spendingData = await fetchSpendingData(user._id, year, month);
    const incomeData = await fetchIncomeData(user._id, year, month);
    const remaining = incomeData.income - spendingData.totalExpenses;
    setMonthData({
      ...spendingData,
      income: incomeData.income,
      remaining,
    });
  };

  const updateNetWorthData = async () => {
    const data = await fetchNetWorthHistory(user._id);
    setNetWorthData(data);
  };

  const fetchSalaryData = async () => {
    try {
      const data = await fetchComparisonData(user._id, "year");
      const { incomePercentile } = data;
      setSalaryData({
        userIncome: incomePercentile.userIncome,
        percentile: incomePercentile.percentile,
        peerSalaries: incomePercentile.peerSalaries,
      });
    } catch (error) {
      console.error("Failed to fetch salary data:", error);
    }
  };

  const handleWidgetSelection = async (widget) => {
    setSelectedWidget(widget);
    if (widget === "Spending") {
      await updateSpendingData();
    } else if (widget === "Networth") {
      await updateNetWorthData();
    } else if (widget === "Salary") {
      await fetchSalaryData();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 500,
          height: "80%", // Set a fixed height for the modal
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
          overflowY: "auto", // Enable scrolling for the modal content
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6">Create Post</Typography>
        </Box>
        <TextField
          fullWidth
          variant="outlined"
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2 }}
        />
        <ReactQuill
          theme="snow"
          value={body}
          onChange={setBody}
          modules={{
            toolbar: [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
            ],
          }}
          formats={[
            "header",
            "font",
            "list",
            "bullet",
            "bold",
            "italic",
            "underline",
            "color",
            "background",
            "align",
          ]}
          style={{ height: "200px", marginBottom: "20px" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            mt: 7.5,
            padding: "10px",
            borderRadius: 1,
            backgroundColor:
              theme.palette.mode === "dark" ? "#333333" : "#F0F0F0",
          }}
        >
          <IconButton onClick={() => handleWidgetSelection("Networth")}>
            <MonetizationOn />
            <Typography variant="caption" sx={{ marginLeft: "5px" }}>
              Networth
            </Typography>
          </IconButton>
          <IconButton onClick={() => handleWidgetSelection("Spending")}>
            <AccountBalanceWallet />
            <Typography variant="caption" sx={{ marginLeft: "5px" }}>
              Spending
            </Typography>
          </IconButton>
          <IconButton onClick={() => handleWidgetSelection("Salary")}>
            <AttachMoney />
            <Typography variant="caption" sx={{ marginLeft: "5px" }}>
              Salary
            </Typography>
          </IconButton>
        </Box>

        {selectedWidget === "Spending" && (
          <Box sx={{ mt: 2 }}>
            <SpendingChartWidget
              userInfo={user._id}
              monthData={monthData}
              setMonthData={setMonthData}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
            />
          </Box>
        )}
        {selectedWidget === "Networth" && (
          <Box sx={{ mt: 2 }}>
            <NetworthChartWidget
              userId={user._id}
              initialData={netWorthData}
              lockTimeWindow={true}
              setNetWorthData={setNetWorthData}
            />
          </Box>
        )}
        {selectedWidget === "Salary" && (
          <Box sx={{ mt: 2 }}>
            <SalaryWidget
              userIncome={salaryData.userIncome}
              percentile={salaryData.percentile}
              peerSalaries={salaryData.peerSalaries}
              country="Canada"
            />
          </Box>
        )}

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", margin: "1rem 0" }}
        >
          <Button
            variant="contained"
            onClick={handlePost}
            disabled={!title || !body}
          >
            Post
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PostCreationWidget;
