import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import blankPhone from "../assets/blank-phone.png";
import blankPhone2 from "../assets/blankComparison.png";
import factsWidget3 from "../assets/factsWidget-3.png";
import netWorthWidget1 from "../assets/netWorthWidget-1.png";
import logo from "../assets/peerspocket_logo.png";
import post1 from "../assets/post1.png";
import post2 from "../assets/post2.png";
import post3 from "../assets/post3.png";
import salaryWidget2 from "../assets/SalaryWidget-2.png";
import ComparisonSection from "./ComparisonSection";
import "./LandingPage.css";

const LandingPage = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    country: "",
    stateProvince: "",
    age: 14,
    profession: "",
    income: 0,
    netWorth: 0,
    showComparisonWidgets: false,
  });

  useEffect(() => {
    const fadeInElements = document.querySelectorAll(".fade-in-slide");
    fadeInElements.forEach((element, index) => {
      setTimeout(() => {
        element.classList.add("animate");
      }, index * 30);
    });
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleScrollToComparison = () => {
    const comparisonSection = document.querySelector(
      ".salary-comparison-section",
    );
    if (comparisonSection) {
      comparisonSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleComparison = () => {
    setState((prevState) => ({
      ...prevState,
      showComparisonWidgets: true,
      shouldUpdateData: true,
    }));
  };

  const handleChange = (field) => (eventOrValue) => {
    const value = eventOrValue.target
      ? eventOrValue.target.value
      : eventOrValue;
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <div className="landing-page-container">
      <header className="landing-header">
        <div
          className="logo-wrapper"
          onClick={() => handleNavigation("/login")}
          style={{ cursor: "pointer" }}
        >
          <img src={logo} alt="Peers Pocket Logo" className="logo" />
        </div>
        <button
          className="peek-button"
          onClick={() => handleNavigation("/home")}
          style={{ cursor: "pointer" }}
        >
          Peek now →
        </button>
      </header>
      <main className="main-content">
        <h1 className="main-heading fade-in-slide fade-in-slide-delay-1">
          See Financial Wisdom from Real People
        </h1>
        <p className="main-subtitle fade-in-slide fade-in-slide-delay-2">
          Let&#39;s Break the Taboo Around Money
        </p>
        <div className="button-wrapper fade-in-slide fade-in-slide-delay-3">
          <button
            className="peek-button main-peek-button"
            onClick={() => handleNavigation("/home")}
            style={{ cursor: "pointer" }}
          >
            Peek now →
          </button>
        </div>
        <div
          className="button-wrapper fade-in-slide fade-in-slide-delay-3"
          style={{ marginTop: "20px" }}
        ></div>
        <div className="images-wrapper fade-in-slide fade-in-slide-delay-4">
          <div className="image-container">
            <img
              src={blankPhone}
              alt="Blank Phone"
              className="blank-phone fade-in-slide fade-in-slide-delay-1"
            />
            <img
              src={post1}
              alt="Post 1"
              className="post-image post-1 fade-in-slide fade-in-slide-delay-4"
            />
            <img
              src={post2}
              alt="Post 2"
              className="post-image post-2 fade-in-slide fade-in-slide-delay-5"
            />
            <img
              src={post3}
              alt="Post 3"
              className="post-image post-3 fade-in-slide fade-in-slide-delay-6"
            />
          </div>
        </div>
        <div className="transparency-section-wrapper">
          <section className="transparency-section">
            <div className="text-container">
              <h2 className="section-heading fade-in-slide fade-in-slide-delay-2">
                The Era of Transparency
              </h2>
              <p className="section-subtitle fade-in-slide fade-in-slide-delay-3">
                See How You Stack Up Against Your Peers (Anonymously, Of Course)
              </p>
            </div>
            <div className="transparency-images-wrapper">
              <div className="image-container">
                <img
                  src={blankPhone2}
                  alt="Blank Phone 2"
                  className="blank-phone-2 fade-in-slide fade-in-slide-delay-11"
                />
                <img
                  src={netWorthWidget1}
                  alt="Net Worth Widget"
                  className="widget-image widget-1 fade-in-slide fade-in-slide-delay-12"
                />
                <img
                  src={salaryWidget2}
                  alt="Salary Widget"
                  className="widget-image widget-2 fade-in-slide fade-in-slide-delay-13"
                />
                <img
                  src={factsWidget3}
                  alt="Facts Widget"
                  className="widget-image widget-3 fade-in-slide fade-in-slide-delay-14"
                />
              </div>
            </div>
          </section>
        </div>
        <ComparisonSection
          state={state}
          setState={setState}
          handleChange={handleChange}
          handleComparison={handleComparison}
        />
      </main>
    </div>
  );
};

export default LandingPage;
