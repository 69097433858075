import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useUser } from "../context/UserContext";

const registerSchema = yup.object().shape({
  userName: yup
    .string()
    .optional()
    .max(50, "Username must be at most 50 characters"),
  ageRange: yup.string().required("required"),
  country: yup.string().required("required"),
  profession: yup.string().required("required"),
  typeOfShopper: yup.string().required("required"),
  salaryRanges: yup.string().optional(),
});

const initialValues = {
  ageRange: "",
  country: "",
  profession: "",
  typeOfShopper: "",
  userName: "",
};

const ageRanges = [
  "16-18",
  "19-24",
  "25-29",
  "30-34",
  "35-39",
  "40-44",
  "45-49",
  "50-54",
  "55-59",
  "60+",
];
const countries = ["Canada", "United States"];
const professions = [
  "Healthcare",
  "Education",
  "Engineering",
  "Technology",
  "Business",
  "Law",
  "Creative Arts",
  "Social Services",
  "Agriculture",
  "Skilled Trades",
  "Science",
  "Media",
  "Hospitality",
  "Transportation",
  "Public Service",
  "Finance",
  "Sales",
  "Marketing",
  "Consulting",
  "Military",
];
const shopperTypes = [
  "Window Shopper",
  "Bargain Hunter",
  "Impulse Buyer",
  "Researcher",
  "Loyal Customer",
  "Trendsetter",
  "Practical Shopper",
];
const salaryRanges = [
  "Less than $20,000",
  "$20,000 - $39,999",
  "$40,000 - $59,999",
  "$60,000 - $79,999",
  "$80,000 - $99,999",
  "$100,000 - $119,999",
  "$120,000 - $139,999",
  "$140,000 - $159,999",
  "$160,000 - $179,999",
  "$180,000 - $199,999",
  "$200,000 - $219,999",
  "$220,000 - $239,999",
  "$240,000 - $259,999",
  "$260,000 - $279,999",
  "$280,000 - $299,999",
  "$300,000 - $319,999",
  "$320,000 - $339,999",
  "$340,000 - $359,999",
  "$360,000 - $379,999",
  "$380,000 - $399,999",
  "$400,000 - $419,999",
  "$420,000 - $439,999",
  "$440,000 - $459,999",
  "$460,000 - $479,999",
  "$480,000 - $499,999",
  "$500,000 or more",
];

export default function Onboarding() {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { user, retry } = useUser();
  const navigate = useNavigate();

  const handleCompleteOnboarding = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/complete-onboarding`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({ userId: user.id }), // Pass the userId in the request body
        },
      );

      if (!response.ok) {
        throw new Error("Failed to complete onboarding");
      }

      const data = await response.json();
      console.log("Onboarding completed:", data);
      await retry();
      navigate("/home");
    } catch (error) {
      console.error("Error completing onboarding:", error);
    }
  };

  useEffect(() => {
    if (user && user.onboarded) {
      navigate("/");
    }
  }, [user, navigate]);

  const handleFormSubmit = async (
    values,
    { setSubmitting, setErrors, resetForm },
  ) => {
    try {
      let formData = {
        ...values,
        salaryRanges: values.salaryRanges || "",
        userId: user.id,
      };
      const savedUserResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/onboard`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify(formData),
        },
      );

      const savedUser = await savedUserResponse.json();
      if (savedUser.error) {
        setErrors(savedUser.error);
        return;
      }

      setAlertMessage("Registration successful!");
      setAlertOpen(true);
      resetForm();

      // Call handleCompleteOnboarding after successful form submission
      await handleCompleteOnboarding();

      setSubmitting(false);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={registerSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              label="Username"
              name="userName"
              value={values.userName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.userName && Boolean(errors.userName)}
              helperText={touched.userName && errors.userName}
              margin="normal"
            />
            <TextField
              select
              fullWidth
              variant="outlined"
              label="Country *"
              name="country"
              value={values.country}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.country && Boolean(errors.country)}
              helperText={touched.country && errors.country}
              margin="normal"
            >
              {countries.map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              fullWidth
              variant="outlined"
              label="Profession *"
              name="profession"
              value={values.profession}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.profession && Boolean(errors.profession)}
              helperText={touched.profession && errors.profession}
              margin="normal"
            >
              {professions.map((profession) => (
                <MenuItem key={profession} value={profession}>
                  {profession}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              fullWidth
              variant="outlined"
              label="Type of Shopper *"
              name="typeOfShopper"
              value={values.typeOfShopper}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.typeOfShopper && Boolean(errors.typeOfShopper)}
              helperText={touched.typeOfShopper && errors.typeOfShopper}
              margin="normal"
            >
              {shopperTypes.map((shopperType) => (
                <MenuItem key={shopperType} value={shopperType}>
                  {shopperType}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              fullWidth
              variant="outlined"
              label="Age Range *"
              name="ageRange"
              value={values.ageRange}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.ageRange && Boolean(errors.ageRange)}
              helperText={touched.ageRange && errors.ageRange}
              margin="normal"
            >
              {ageRanges.map((ageRange) => (
                <MenuItem key={ageRange} value={ageRange}>
                  {ageRange}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              fullWidth
              variant="outlined"
              label="Salary Range (optional)"
              name="salaryRanges"
              value={values.salaryRanges}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.salaryRanges && Boolean(errors.salaryRanges)}
              helperText={touched.salaryRanges && errors.salaryRanges}
              margin="normal"
            >
              {salaryRanges.map((salaryRange) => (
                <MenuItem key={salaryRange} value={salaryRange}>
                  {salaryRange}
                </MenuItem>
              ))}
            </TextField>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              fullWidth
              sx={{ mt: 2 }}
            >
              Done!
            </Button>
            <Collapse in={alertOpen}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => setAlertOpen(false)}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mt: 2 }}
              >
                {alertMessage}
              </Alert>
            </Collapse>
          </form>
        )}
      </Formik>
    </Box>
  );
}
