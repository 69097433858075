import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import Navbar from "./NavBar";
import RecommendedPosts from "./RecommendedPosts";
import PostCreationButton from "./widgets/PostCreationButton";
import PostCreationWidget from "./widgets/PostCreationWidget";

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isNonMobileScreen = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();
  const { user } = useUser();

  const handleOpenModal = () => {
    if (!user) {
      navigate("/login");
    } else if (user && !user.onboarded) {
      navigate("/onboarding");
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Navbar />
      <Box
        width="100%"
        paddingTop={"1rem"}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="0.5rem"
      >
        <Box width={"100%"}>
          <RecommendedPosts />
        </Box>
      </Box>

      {!isModalOpen && (
        <>
          <PostCreationButton onClick={handleOpenModal} />
          {isNonMobileScreen && (
            <PostCreationButton onClick={handleOpenModal} />
          )}
        </>
      )}

      {isModalOpen && (
        <PostCreationWidget open={isModalOpen} onClose={handleCloseModal} />
      )}
    </Box>
  );
};

export default HomePage;
