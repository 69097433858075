import { Box, Skeleton, Typography, useTheme } from "@mui/material";

import "chart.js/auto";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { randomDelay } from "../../utils/randomDelay.js";

const mockPeerNetworths = [
  -20000, 0, 15000, 30000, 50000, 75000, 100000, 150000, 200000, 250000, 350000,
  500000,
];

const NetworthWidgetLandingPage = ({
  province,
  userNetworth,
  updateData,
  setUpdateData,
}) => {
  const theme = useTheme();
  const [selectedCountry, setSelectedCountry] = useState(province || "Canada");
  const [percentile, setPercentile] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (updateData) {
      setLoading(true);
      const calculatePercentile = (networth) => {
        const index = mockPeerNetworths.findIndex((nw) => nw >= networth);
        return ((index / mockPeerNetworths.length) * 100).toFixed(2);
      };

      setTimeout(
        () => {
          setPercentile(calculatePercentile(userNetworth));
          setLoading(false);
          setUpdateData(false); // Reset updateData after updating the data
        },
        randomDelay(3000, 4200),
      );
    }
  }, [updateData, userNetworth, setUpdateData]);

  useEffect(() => {
    if (province) {
      setSelectedCountry(province);
    }
  }, [province]);

  const roundedPercentile = Math.round(percentile);

  const data = {
    labels: mockPeerNetworths.map((_, index) => `${index + 1}`),
    datasets: [
      {
        label: "Net Worth",
        data: mockPeerNetworths,
        backgroundColor: mockPeerNetworths.map((_, index) =>
          index === mockPeerNetworths.findIndex((nw) => nw >= userNetworth)
            ? "#D6D273"
            : "#1B3938",
        ),
        borderRadius: 5,
      },
    ],
  };

  const options = {
    scales: {
      x: { display: false },
      y: { display: false },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `$${Math.round(context.raw).toLocaleString()}`;
          },
          title: function (context) {
            if (
              context[0].dataIndex ===
              mockPeerNetworths.findIndex((nw) => nw >= userNetworth)
            ) {
              return "User";
            }
            return "";
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const textSegments = [
    {
      text: "Your net worth of",
      bold: true,
      color: theme.palette.text.secondary,
    },
    {
      text: `~$${Math.round(userNetworth || 0).toLocaleString()}`,
      bold: true,
      color: "#D6D273",
    },
    {
      text: "ranks you at the",
      bold: true,
      color: theme.palette.text.secondary,
    },
    {
      text: `${roundedPercentile}th`,
      bold: true,
      color: "#D6D273",
    },
    {
      text: "percentile compared to your",
      bold: true,
      color: theme.palette.text.secondary,
    },
    {
      text: "peers",
      bold: true,
      color: "#D6D273",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.alt,
        borderRadius: 2,
        padding: 3,
        width: "100%",
        maxWidth: "400px", // Ensures the widget is square
        height: "380px", // Ensures the widget is square
        color: theme.palette.text.primary,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginTop: 4,
      }}
    >
      {loading ? (
        <Box>
          <Skeleton variant="text" sx={{ fontSize: "1.10rem", width: "70%" }} />
          <Skeleton
            variant="rectangular"
            sx={{ marginTop: 5, height: "160px", width: "100%" }}
          />
          <Skeleton variant="text" sx={{ marginTop: 5, width: "50%" }} />
          <Skeleton variant="text" sx={{ width: "60%" }} />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: "medium", fontSize: "1.10rem" }}>
              Net Worth Comparison
            </Typography>
            {/* <Select
              value="Canada"
              title="Canada"
              onChange={(e) => setSelectedCountry(e.target.value)}
              disabled
              sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: 1,
                padding: "0.25rem 1rem",
                color: theme.palette.text.primary,
              }}
            >
              <MenuItem value="Canada">Canada</MenuItem>
            </Select> */}
          </Box>
          <Box sx={{ marginTop: 5, position: "relative", height: "160px" }}>
            <Bar data={data} options={options} />
            {mockPeerNetworths.findIndex((nw) => nw >= userNetworth) !== -1 &&
              !loading && (
                <Typography
                  sx={{
                    position: "absolute",
                    left: `${
                      (mockPeerNetworths.findIndex((nw) => nw >= userNetworth) /
                        mockPeerNetworths.length) *
                      100
                    }%`,
                    bottom: "100%",
                    transform: "translateX(-50%)",
                    color: "#D6D273",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginLeft: 2,
                  }}
                >
                  You
                </Typography>
              )}
          </Box>
          <Box
            sx={{
              textAlign: "center",
              color: theme.palette.text.secondary,
              marginTop: 2,
            }}
          >
            {textSegments.map((segment, index) => (
              <Typography
                key={index}
                component="span"
                sx={{
                  fontWeight: segment.bold ? "bold" : "normal",
                  marginRight: 0.5,
                  fontSize: "0.9rem",
                  color: segment.color,
                }}
              >
                {segment.text}
              </Typography>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};
export default NetworthWidgetLandingPage;
