import { Box, Skeleton } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { usePosts, usePostsDispatch } from "../context/PostsContext";
import PostWidget from "./widgets/PostWidget";

const PostFeed = () => {
  const { posts: results, loading: loadingPage, hasMore } = usePosts();
  const { loadMore } = usePostsDispatch();

  return (
    <Box style={{ padding: "0rem", overflow: "hidden" }}>
      {loadingPage && (
        <Box>
          {Array.from(new Array(5)).map((_, index) => (
            <Box key={index} padding="1rem">
              <Skeleton variant="rectangular" height={118} animation="pulse" />
              <Skeleton width="60%" animation="pulse" />
              <Skeleton width="40%" animation="pulse" />
            </Box>
          ))}
        </Box>
      )}
      {!loadingPage && (
        <InfiniteScroll
          dataLength={results.length}
          loader={
            <Box style={{ textAlign: "center", marginTop: "2rem" }}>
              <Skeleton variant="rectangular" height={118} animation="pulse" />
              <Skeleton width="60%" animation="pulse" />
              <Skeleton width="40%" animation="pulse" />
            </Box>
          }
          next={loadMore}
          hasMore={hasMore}
          endMessage={
            <Box style={{ textAlign: "center", marginTop: "2rem" }}>
              No More Results
            </Box>
          }
        >
          {results.map(
            (
              {
                _id,
                author,
                description,
                location,
                postImage,
                likes,
                comments,
                showLikes,
                showComments,
                title,
                createdAt,
                spendingData,
                netWorthData,
                salaryData,
              },
              index,
            ) => (
              <Box
                key={_id + index}
                style={{
                  paddingBottom: "0rem",
                  borderBottom: "1px solid #225050",
                }}
              >
                <PostWidget
                  postId={_id}
                  user={author}
                  description={description}
                  location={location}
                  postImage={postImage}
                  likes={likes}
                  title={title}
                  comments={comments}
                  showLikes={showLikes}
                  showComments={showComments}
                  createdAt={createdAt}
                  spendingData={spendingData}
                  netWorthData={netWorthData}
                  salaryData={salaryData}
                />
              </Box>
            ),
          )}
        </InfiniteScroll>
      )}
    </Box>
  );
};

export default PostFeed;
