const API_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchSinglePost = async (postId) => {
  const url = `${API_URL}/api/posts/post/${postId}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.error("Failed to fetch post, response status:", response.status);
      throw new Error("Failed to fetch post");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching post:", error);
    throw error;
  }
};

export const fetchComparisonData = async (userId, timePeriod) => {
  const response = await fetch(
    `${API_URL}/api/comparison/${userId}/${timePeriod}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data;
};

export const postComparisonData = async (comparisonData) => {
  const url = `${API_URL}/api/landingPageData`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comparisonData),
    });

    if (!response.ok) {
      console.error(
        "Failed to post comparison data, response status:",
        response.status,
      );
      throw new Error("Failed to post comparison data");
    }

    const data = await response.json();
    console.log("Comparison data posted successfully hhhhs:", data);
    return data;
  } catch (error) {
    console.error("Error posting comparison data:", error);
    throw error;
  }
};

export const fetchSpendingData = async (userId, year, month) => {
  const response = await fetch(
    `${API_URL}/api/spending/${userId}/${year}/${month}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch spending data");
  }

  const data = await response.json();
  return data;
};

export const fetchIncomeData = async (userId, year, month) => {
  const response = await fetch(
    `${API_URL}/api/income/${userId}/${year}/${month}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch income data");
  }

  const data = await response.json();
  return data;
};

export const fetchAggregatedAssetsAndLiabilities = async (userId) => {
  const response = await fetch(
    `${API_URL}/api/networth/networth/aggregated/${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch aggregated assets and liabilities");
  }

  const data = await response.json();
  return data;
};

export const fetchNetWorthHistory = async (userId) => {
  const response = await fetch(
    `${API_URL}/api/networth/networth/history/${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch net worth history");
  }

  const data = await response.json();
  return data;
};

export const fetchUserData = async (userId) => {
  const response = await fetch(`${API_URL}/api/users/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user data");
  }

  return await response.json();
};
