import useUrlParam from "../hooks/useUrlParam";

export const usePostFilter = () => useUrlParam("postFilter", "new");
export const usePostQuery = () => useUrlParam("postQuery", undefined);
export const usePostLimit = () => useUrlParam("postLimit", "10");
export const usePostBatch = () => useUrlParam("postBatch", "1");

// The above is the pattern for creating custom hooks that manage URL parameters.
// This will automatically update the URL when the value changes and vice versa.

// Using this pattern will also allow us to deep link to specific pages in our app.
