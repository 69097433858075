import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Fab,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";

const categories = [
  { value: "Miscellaneous", label: "Miscellaneous" },
  { value: "Housing", label: "Housing" },
  { value: "Utilities", label: "Utilities" },
  { value: "Groceries", label: "Groceries" },
  { value: "Transportation", label: "Transportation" },
];

const assetCategories = [
  { value: "Real Estate", label: "Real Estate" },
  { value: "Stocks", label: "Stocks" },
  { value: "Savings", label: "Savings" },
  { value: "Other", label: "Other" },
];

const liabilityCategories = [
  { value: "Loan", label: "Loan" },
  { value: "Credit Card", label: "Credit Card" },
  { value: "Mortgage", label: "Mortgage" },
  { value: "Other", label: "Other" },
];

const initialFormData = {
  amount: 0,
  category: "Miscellaneous",
  note: "",
  date: dayjs().format("YYYY-MM-DD"),
  repeat: "Never repeat",
};

const AddButtonWidget = ({ currentTab, userInfo }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [transactionType, setTransactionType] = useState("Spending");
  const [netWorthType, setNetWorthType] = useState("Asset");
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFormData(initialFormData); // Reset form data when closing
    setError(null); // Reset error when closing
  };

  const handleTypeChange = (event, newType) => {
    setTransactionType(newType);
    setFormData({ ...formData, category: "Miscellaneous" });
  };

  const handleNetWorthTypeChange = (event) => {
    setNetWorthType(event.target.value);
    setFormData({ ...formData, category: "Other" });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      let url;
      let payload;

      // Ensure date is formatted correctly as a string
      const formattedDate = new Date(formData.date).toISOString();

      if (transactionType === "Net Worth") {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/networth/${netWorthType === "Asset" ? "asset" : "liability"}`;
        payload = {
          userId: userInfo.id,
          name: formData.note,
          value: formData.amount,
          type: formData.category,
          currency: "USD",
          date: formattedDate, // Ensure the date is included in the payload
        };
      } else if (transactionType === "Spending") {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/spending`;
        payload = {
          userId: userInfo.id,
          value: formData.amount,
          category: formData.category,
          note: formData.note,
          date: formattedDate,
        };
      } else if (transactionType === "Income") {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/income`;
        payload = {
          userId: userInfo.id,
          value: formData.amount,
          category: formData.category,
          note: formData.note,
          date: formattedDate,
        };
      }

      if (url && payload) {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const result = await response.json();
          if (transactionType === "Net Worth") {
            await updateNetWorth(userInfo.id, formattedDate);
          }
          setFormData(initialFormData); // Reset form data after successful submission
          setError(null); // Clear any previous errors
          handleClose(); // Close the modal after successful submission
        } else {
          const errorData = await response.json();
          console.error("Response error data:", errorData);
          setError(
            `Failed to add ${transactionType.toLowerCase()}: ${errorData.message}`,
          );
        }
      }
    } catch (error) {
      console.error("Submission error:", error);
      setError("Error: " + error.message);
    }
  };
  const updateNetWorth = async (userId, date) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/networth/updateNetworth/${userId}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Failed to update net worth:", errorData);
        setError(`Failed to update net worth: ${errorData.message}`);
      } else {
        console.log("Net worth updated successfully");
      }
    } catch (error) {
      console.error("Error updating net worth:", error);
      setError(`Error updating net worth: ${error.message}`);
    }
  };

  return (
    <Box>
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleOpen}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          backgroundColor: theme.palette.secondary.main,
          "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
          },
        }}
      >
        <AddIcon />
      </Fab>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 320,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">New Transaction</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              ${formData.amount}
            </Typography>
          </Box>
          <ToggleButtonGroup
            value={transactionType}
            exclusive
            onChange={handleTypeChange}
            aria-label="transaction type"
            fullWidth
            sx={{ mb: 2 }}
          >
            <ToggleButton value="Spending">Spending</ToggleButton>
            <ToggleButton value="Income">Income</ToggleButton>
            <ToggleButton value="Net Worth">Net Worth</ToggleButton>
          </ToggleButtonGroup>
          {transactionType === "Net Worth" && (
            <TextField
              select
              label="Type"
              name="netWorthType"
              value={netWorthType}
              onChange={handleNetWorthTypeChange}
              fullWidth
              sx={{ mb: 2 }}
            >
              <MenuItem value="Asset">Asset</MenuItem>
              <MenuItem value="Liability">Liability</MenuItem>
            </TextField>
          )}
          <TextField
            label="Amount"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            type="number"
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            select
            label="Category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          >
            {(transactionType === "Net Worth"
              ? netWorthType === "Asset"
                ? assetCategories
                : liabilityCategories
              : categories
            ).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Note"
            name="note"
            value={formData.note}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Date"
            name="date"
            type="date"
            value={formData.date}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {transactionType !== "Net Worth" && (
            <TextField
              select
              label="Repeat"
              name="repeat"
              value={formData.repeat}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            >
              <MenuItem value="Never repeat">Never repeat</MenuItem>
              <MenuItem value="Daily">Daily</MenuItem>
              <MenuItem value="Weekly">Weekly</MenuItem>
              <MenuItem value="Monthly">Monthly</MenuItem>
            </TextField>
          )}
          {error && (
            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddButtonWidget;
