import {
  ChatBubbleOutlineOutlined,
  Delete,
  EmojiEmotions,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  MoreVert as MoreVertIcon,
  Verified as VerifiedIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import EmojiPicker from "emoji-picker-react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { usePostsDispatch } from "../../context/PostsContext";
import { useUser } from "../../context/UserContext";
import { timeAgo } from "../../utils/timeUtils";
import FlexBetween from "../UI/FlexBetween";
import UserImage from "../UI/UserImage";
import WidgetWrapper from "../UI/WidgetWrapper";
import NetworthChartWidget from "./NetworthChartWidget";
import PostUpdateWidget from "./PostUpdateWidget";
import SalaryWidget from "./SalaryWidget";
import SpendingChartWidget from "./SpendingChartWidget";

const PostWidget = ({
  postId,
  user,
  description,
  spendingData,
  likes,
  comments,
  showLikes,
  showComments,
  title,
  createdAt,
  netWorthData,
  salaryData,
}) => {
  const [isComments, setIsComments] = useState(false);
  const [comment, setComment] = useState("");
  const [isPostCreationOpen, setIsPostCreationOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { user: loggedInUser } = useUser();
  const isLiked = useMemo(
    () => Boolean(likes[loggedInUser?._id]),
    [likes, loggedInUser],
  );
  const likeCount = useMemo(() => Object.keys(likes).length, [likes]);
  const inputRef = useRef();
  const { likePost, commentPost, deletePost, deleteComment } =
    usePostsDispatch();
  const [currentMonth, setCurrentMonth] = useState(dayjs().format("MMMM YYYY"));
  const [monthData, setMonthData] = useState(spendingData);
  const navigate = useNavigate();

  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
    inputRef.current.focus();
  };

  const userProfilePhoto = user?.profilePhoto;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const stripHtml = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const truncateText = (text, length) => {
    const plainText = stripHtml(text);
    if (plainText.length <= length) return text;
    return `${plainText.substring(0, length)}...`;
  };

  const handleUpdatePost = () => {
    setIsPostCreationOpen(true);
    handleMenuClose();
  };

  const handleDeletePost = useCallback(async () => {
    // TODO
    deletePost(postId);
    handleMenuClose();
  }, [deletePost, postId]);

  const handleNavigate = () => {
    const formattedTitle = title.replace(/ /g, "-");
    navigate(`/posts/post/${postId}/${formattedTitle}`);
  };

  const handleLike = () => {
    if (!loggedInUser) {
      navigate("/login");
      return;
    } else if (!loggedInUser.onboarded) {
      navigate("/onboarding");
      return;
    }

    likePost(postId);
  };

  const handleCommentToggle = () => {
    if (!loggedInUser) {
      navigate("/login");
      return;
    } else if (!loggedInUser.onboarded) {
      navigate("/onboarding");
      return;
    }

    setIsComments(!isComments);
  };

  return (
    <>
      <WidgetWrapper
        sx={{
          backgroundColor: theme.palette.background.alt,
          borderRadius: 1,
          padding: 2,
          color: theme.palette.text.primary,
        }}
      >
        <FlexBetween
          sx={{
            marginBottom: 2,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FlexBetween>
            <Link to={`/profile/${user?._id}`}>
              <UserImage image={userProfilePhoto} size="40px" />
            </Link>
            <Box sx={{ marginLeft: 1 }}>
              <FlexBetween>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    marginRight: 1,
                    cursor: "pointer",
                    color: theme.palette.text.primary,
                    textDecoration: "none",
                  }}
                  component={Link}
                  to={`/profile/${user?._id}`}
                >
                  {user?.username || "Unknown"}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.success.main,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {user?.verified && (
                    <VerifiedIcon sx={{ fontSize: "1rem", marginRight: 0.5 }} />
                  )}
                  {`$${
                    user?.userNetWorth ? user.userNetWorth.toLocaleString() : 0
                  }`}
                </Typography>
              </FlexBetween>
              <Typography sx={{ color: theme.palette.text.secondary }}>
                {timeAgo(createdAt)}
              </Typography>
            </Box>
          </FlexBetween>
          {user._id === loggedInUser?._id && (
            <>
              <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleUpdatePost}>Update Post</MenuItem>
                <MenuItem onClick={handleDeletePost}>Delete Post</MenuItem>
              </Menu>
            </>
          )}
        </FlexBetween>
        <Typography
          sx={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            marginBottom: 1,
            cursor: "pointer",
          }}
          onClick={handleNavigate}
        >
          {title}
        </Typography>
        <Typography
          sx={{ marginBottom: 2, cursor: "pointer" }}
          onClick={handleNavigate}
          dangerouslySetInnerHTML={{
            __html: isExpanded ? description : truncateText(description, 100), // Adjust truncate length as needed
          }}
        />

        {spendingData && (
          <Box
            onClick={handleNavigate}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxWidth: "300px",
              height: "auto",
              margin: "0 auto",
              outline: "black",
              cursor: "pointer",
            }}
          >
            <SpendingChartWidget
              userInfo={user}
              monthData={monthData}
              setMonthData={setMonthData}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              lockMonthChanger={true} // Lock the month changer
              initialData={monthData} // Pass initial data if available
            />
          </Box>
        )}
        {netWorthData && netWorthData.length > 0 && (
          <Box
            onClick={handleNavigate}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              // maxWidth: "300px",
              height: "auto",
              margin: "0 auto",
              outline: "black",
              cursor: "pointer",
            }}
          >
            <NetworthChartWidget
              userId={user._id}
              initialData={netWorthData}
              lockTimeWindow={true}
              setNetWorthData={() => {}}
            />
          </Box>
        )}
        {salaryData && (
          <Box
            onClick={handleNavigate}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              // maxWidth: "300px",
              height: "auto",
              margin: "0 auto",
              outline: "black",
              cursor: "pointer",
            }}
          >
            <SalaryWidget
              userIncome={salaryData.userIncome}
              percentile={salaryData.percentile}
              peerSalaries={salaryData.peerSalaries}
              country="Canada"
            />
          </Box>
        )}
        <FlexBetween sx={{ marginTop: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => handleLike()}>
              {isLiked ? (
                <FavoriteOutlined sx={{ color: theme.palette.error.main }} />
              ) : (
                <FavoriteBorderOutlined />
              )}
            </IconButton>
            <Typography sx={{ marginRight: 2 }}>{likeCount} Likes</Typography>
            <IconButton onClick={() => handleCommentToggle()}>
              <ChatBubbleOutlineOutlined />
            </IconButton>
            <Typography>{comments.length} Comments</Typography>
          </Box>
        </FlexBetween>
        {isComments && (
          <Box mt={2}>
            {comments.map((comment, index) => (
              <Box key={index}>
                <Divider />
                <Box
                  sx={{ display: "flex", alignItems: "center", mt: 1, mb: 1 }}
                >
                  <UserImage image={comment.image} size="30px" />
                  <Box sx={{ ml: 1, flexGrow: 1 }}>
                    <Typography variant="body2">
                      <strong>{comment.name}</strong> {comment.comment}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: theme.palette.text.secondary }}
                    >
                      {timeAgo(comment.createdAt)}
                    </Typography>
                  </Box>
                  {comment.userId === loggedInUser?._id && (
                    <IconButton
                      onClick={() => {
                        deleteComment(postId, index); // Ideally these should be ids
                      }}
                    >
                      <Delete sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            ))}
            <Divider />
            <Box sx={{ display: "flex", mt: 1 }}>
              <UserImage image={loggedInUser?.profilePhoto} size="30px" />
              <Box sx={{ ml: 1, flexGrow: 1 }}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    commentPost(postId, {
                      name: loggedInUser?.username,
                      image: loggedInUser?.profilePhoto,
                      comment,
                    });
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder="Write a comment..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    inputRef={inputRef}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => setOpen(true)}>
                          <EmojiEmotions />
                        </IconButton>
                      ),
                    }}
                  />
                  <Button type="submit" sx={{ display: "none" }} />
                </form>
              </Box>
            </Box>
          </Box>
        )}
        <Dialog open={open} onClose={handleClose}>
          <EmojiPicker
            onEmojiClick={(event, emojiObject) => {
              setComment((prev) => prev + emojiObject.emoji);
              handleClose();
            }}
          />
        </Dialog>
      </WidgetWrapper>
      {/* Post Creation Widget for Update */}
      {isPostCreationOpen && (
        <PostUpdateWidget
          open={isPostCreationOpen}
          onClose={() => setIsPostCreationOpen(false)}
          postData={{
            title,
            postId,
            description,
            spendingData,
            netWorthData,
          }}
        />
      )}
    </>
  );
};

export default PostWidget;
