import { Box } from "@mui/material";
import NavBar from "./NavBar";
import PostFeed from "./PostFeed";

const SearchPostsPage = () => {
  return (
    <Box>
      <NavBar />
      <Box
        width="100%"
        paddingTop={"1rem"}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="0.5rem"
      >
        <Box width={"100%"}>
          <PostFeed />
        </Box>
      </Box>
    </Box>
  );
};

export default SearchPostsPage;
