import { useTheme } from "@emotion/react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BusinessIcon from "@mui/icons-material/Business";
import HomeIcon from "@mui/icons-material/Home";
import { Box, Skeleton, Tab, Tabs, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchAggregatedAssetsAndLiabilities,
  fetchComparisonData,
  fetchNetWorthHistory,
} from "../Api";
import { useUser } from "../context/UserContext";
import Navbar from "./NavBar";
import PostFeed from "./PostFeed";
import ProfilePosts from "./ProfilePosts";
import AddButtonWidget from "./widgets/AddButtonWidget";
import AssetLiabilityWidget from "./widgets/AssetLiabilityWidget";
import FactsWidget from "./widgets/FactsWidget";
import NetworthChartWidget from "./widgets/NetworthChartWidget";
import NetworthWidget from "./widgets/NetworthWidget";
import SalaryWidget from "./widgets/SalaryWidget";
import SpendingByCategoryWidget from "./widgets/SpendingByCategoryWidget";
import SpendingChartWidget from "./widgets/SpendingChartWidget";
import UserProfileHeaderWidget from "./widgets/UserProfileHeaderWidget";

const ProfilePage = () => {
  const theme = useTheme();
  const { user: loggedInUser } = useUser();
  const { userID } = useParams();

  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [user, setUser] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [reRender] = useState(false);
  const [timeWindow] = useState("year");
  const [currentMonth, setCurrentMonth] = useState(dayjs().format("MMMM YYYY"));
  const [searchTerm, setSearchTerm] = useState("");

  // Function to check if the logged-in user is viewing their own profile
  const ownProfile = useMemo(() => {
    return loggedInUser?._id === userID;
  }, [loggedInUser, userID]);

  const getIconForType = (type) => {
    switch (type) {
      case "Real Estate":
        return <HomeIcon />;
      case "Business":
        return <BusinessIcon />;
      case "Savings":
        return <AccountBalanceIcon />;
      case "Investments":
        return <AccountBalanceWalletIcon />;
      default:
        return <AccountBalanceIcon />;
    }
  };

  const [monthData, setMonthData] = useState({
    transactions: 0,
    totalExpenses: 0,
    income: 0,
    remaining: 0,
    categories: [],
  });

  const [netWorthData, setNetWorthData] = useState([]); // Corrected this line
  const [comparisonData, setComparisonData] = useState(null);
  const [assetsAndLiabilities, setAssetsAndLiabilities] = useState([]);

  useEffect(() => {
    const fetchAssetsAndLiabilities = async () => {
      try {
        const data = await fetchAggregatedAssetsAndLiabilities(userID);
        const formattedData = [
          ...data.assets.map((asset) => ({
            icon: getIconForType(asset.type),
            name: asset.type,
            value: asset.totalValue,
          })),
          ...data.liabilities.map((liability) => ({
            icon: getIconForType(liability.type),
            name: liability.type,
            value: -liability.totalValue, // Negate the value for liabilities
          })),
        ];
        setAssetsAndLiabilities(formattedData);
      } catch (error) {
        console.error("Failed to fetch assets and liabilities:", error);
      }
    };

    fetchAssetsAndLiabilities();
  }, [userID]);

  useEffect(() => {
    const fetchUser = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/${userID}`,
        {
          method: "GET",
        },
      );
      const data = await response.json();
      setUser(data);
    };
    fetchUser();
  }, [userID, reRender]);

  useEffect(() => {
    const fetchNetWorth = async () => {
      try {
        const data = await fetchNetWorthHistory(userID);
        setNetWorthData(data);
      } catch (error) {
        console.error("Failed to fetch net worth history:", error);
        setNetWorthData([]); // Handle empty data scenario
      }
    };

    fetchNetWorth();
  }, [userID, setNetWorthData]);

  useEffect(() => {
    const fetchComparison = async () => {
      try {
        const data = await fetchComparisonData(userID, timeWindow);
        setComparisonData(data);
      } catch (error) {
        console.error("Failed to fetch comparison data:", error);
      }
    };

    fetchComparison();
  }, [userID, timeWindow]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderSkeleton = () => {
    return (
      <Box sx={{ padding: isNonMobileScreens ? "2rem" : "1rem" }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Skeleton variant="circular" width={80} height={80} />
          <Box sx={{ ml: 2 }}>
            <Skeleton width="40%" height={30} />
            <Skeleton width="60%" height={20} />
          </Box>
        </Box>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={150}
          sx={{ mb: 2 }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={150}
          sx={{ mb: 2 }}
        />
        <Skeleton variant="rectangular" width="100%" height={150} />
      </Box>
    );
  };

  if (!user || !comparisonData) return renderSkeleton();

  const userNetWorth = comparisonData.netWorthPercentile.userNetWorth;
  const netWorthPercentile = comparisonData.netWorthPercentile.percentile;
  const peerNetWorths = comparisonData.netWorthPercentile.peerNetWorths || [];
  const userIncome = comparisonData.incomePercentile.userIncome;
  const incomePercentile = comparisonData.incomePercentile.percentile;
  const peerSalaries = comparisonData.incomePercentile.peerSalaries || [];
  const userCountry = "Canada"; // Assuming the user's country is Canada

  return (
    <Box sx={{ width: "100%", bgcolor: theme.palette.background.default }}>
      <Navbar user={loggedInUser} onSearch={setSearchTerm} />
      {searchTerm ? (
        <PostFeed />
      ) : (
        <>
          <Box>
            <UserProfileHeaderWidget User={user} />
          </Box>
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab label="Comparison" />
            <Tab label="Networth" />
            <Tab label="Spending" />
            <Tab label="Posts" />
          </Tabs>
          {tabValue === 0 && (
            <Box
              sx={{
                padding: isNonMobileScreens ? "2rem" : "1rem",
                backgroundColor: theme.palette.background.default,
                minHeight: "100vh",
              }}
            >
              <Box sx={{ marginBottom: 3 }}>
                <NetworthWidget
                  netWorth={userNetWorth}
                  percentile={netWorthPercentile}
                  country={userCountry}
                  peerNetWorths={peerNetWorths}
                />
              </Box>
              <Box sx={{ marginBottom: 3 }}>
                <SalaryWidget
                  userIncome={userIncome}
                  percentile={incomePercentile}
                  country={userCountry}
                  peerSalaries={peerSalaries}
                />
              </Box>
              <Box sx={{ marginBottom: 3 }}>
                <FactsWidget />
              </Box>
            </Box>
          )}
          {tabValue === 1 && (
            <Box
              sx={{
                padding: isNonMobileScreens ? "2rem" : "1rem",
                // minHeight: "100vh",
              }}
            >
              <Box sx={{ marginBottom: 3 }}>
                <NetworthChartWidget userId={userID} />
              </Box>
              <Box sx={{ marginBottom: 3 }}>
                <AssetLiabilityWidget data={assetsAndLiabilities} />
              </Box>
              {ownProfile && (
                <AddButtonWidget
                  currentTab={tabValue}
                  userInfo={loggedInUser}
                />
              )}
            </Box>
          )}
          {tabValue === 2 && (
            <Box
              sx={{
                padding: isNonMobileScreens ? "2rem" : "1rem",
                // minHeight: "100vh",
              }}
            >
              <Box sx={{ marginBottom: 3 }}>
                <SpendingChartWidget
                  userInfo={userID}
                  monthData={monthData}
                  setMonthData={setMonthData}
                  currentMonth={currentMonth}
                  setCurrentMonth={setCurrentMonth}
                />
              </Box>
              <Box sx={{ marginBottom: 3 }}>
                <SpendingByCategoryWidget
                  data={monthData}
                  categories={monthData.categories}
                  ownProfile={ownProfile}
                />
              </Box>
              <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                {ownProfile && (
                  <AddButtonWidget
                    currentTab={tabValue}
                    userInfo={loggedInUser}
                  />
                )}
              </Box>
            </Box>
          )}
          {tabValue === 3 && loggedInUser && <ProfilePosts />}
        </>
      )}
    </Box>
  );
};

export default ProfilePage;
