import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { usePostFilter } from "../utils/queryParamUtils";

export const PostFilter = () => {
  const [filter, setFilter] = usePostFilter();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mb={2}
    >
      <ToggleButtonGroup
        value={filter}
        exclusive
        aria-label="Filter posts"
        onChange={(event, newFilter) => {
          if (newFilter !== null) {
            setFilter(newFilter);
          }
        }}
      >
        <ToggleButton value="new" aria-label="New">
          New
        </ToggleButton>
        <ToggleButton value="popular" aria-label="Popular">
          Popular
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
