// model.jsx
import MenuItem from "@mui/material/MenuItem";
import React from "react";

export const professions = [
  "Healthcare",
  "Education",
  "Engineering",
  "Technology",
  "Business",
  "Law",
  "Creative Arts",
  "Social Services",
  "Agriculture",
  "Skilled Trades",
  "Science",
  "Media",
  "Hospitality",
  "Transportation",
  "Public Service",
  "Finance",
  "Sales",
  "Marketing",
  "Consulting",
  "Military",
];

export const provinces = [
  "Ontario",
  "Quebec",
  "British Columbia",
  "Alberta",
  "Manitoba",
  "Saskatchewan",
  "Nova Scotia",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Prince Edward Island",
  "Northwest Territories",
  "Yukon",
  "Nunavut",
];

export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const ProfessionMenuItems = professions.map((profession) => (
  <MenuItem key={profession} value={profession}>
    {profession}
  </MenuItem>
));
export const ProvinceMenuItems = provinces.map((province) => (
  <MenuItem key={province} value={province}>
    {province}
  </MenuItem>
));

export const StateMenuItems = states.map((state) => (
  <MenuItem key={state} value={state}>
    {state}
  </MenuItem>
));
