import { useClerk } from "@clerk/clerk-react";
import { Close, DarkMode, Help, LightMode, Menu } from "@mui/icons-material";
import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../assets/peerspocket_logo.png";
import { useUser } from "../context/UserContext.jsx";
import { setMode } from "../store/index";
import FlexBetween from "./UI/FlexBetween";
import Notifications from "./widgets/Notifications.jsx";
import SearchBar from "./widgets/SearchBar.jsx";

const NavBar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;
  const { signOut } = useClerk();
  const { user } = useUser();
  const username = user?.username;

  const handleProfileNavigation = (userId) => {
    if (!user) {
      navigate("/login");
    } else if (!user.onboarded) {
      navigate("/onboarding");
    } else {
      navigate(`/profile/${userId}`);
    }
  };

  return (
    <FlexBetween
      style={{
        padding: "1rem 6%",
        backgroundColor: alt,
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 99,
        width: "100%",
      }}
    >
      <FlexBetween gap="1.75rem">
        <img
          src={logo}
          alt="Company Logo"
          style={{ height: "40px", cursor: "pointer" }}
          onClick={() => navigate("/home")}
        />
      </FlexBetween>

      {isNonMobileScreens && <SearchBar />}

      <FlexBetween gap="1.75rem">
        {isNonMobileScreens ? (
          <>
            <IconButton onClick={() => dispatch(setMode())}>
              {theme.palette.mode === "dark" ? (
                <DarkMode style={{ fontSize: "25px" }} />
              ) : (
                <LightMode style={{ color: dark, fontSize: "25px" }} />
              )}
            </IconButton>
            {user && <Notifications userId={user._id} />}
            <FormControl variant="standard">
              <Select
                value={username || ""}
                input={<InputBase />}
                displayEmpty
                renderValue={() => (
                  <Avatar
                    src={
                      user ? `data:image/png;base64,${user?.profilePhoto}` : ""
                    }
                    alt={user?.username}
                  />
                )}
              >
                {user ? (
                  <>
                    <MenuItem
                      value={username}
                      onClick={() => handleProfileNavigation(user?._id)}
                    >
                      <Avatar
                        src={`data:image/png;base64,${user?.profilePhoto}`}
                        alt={user?.username}
                        style={{ marginRight: "10px" }}
                      />
                      <Typography style={{ marginLeft: "10px" }}>
                        {username}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => navigate(`/update/${user._id}`)}>
                      Update Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate(`/update/${user._id}/password`)}
                    >
                      Update Password
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        signOut();
                      }}
                    >
                      Log out
                    </MenuItem>
                  </>
                ) : (
                  <MenuItem onClick={() => navigate(`/login`)}>Log in</MenuItem>
                )}
              </Select>
            </FormControl>
          </>
        ) : (
          <IconButton
            onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
          >
            <Menu />
          </IconButton>
        )}
      </FlexBetween>

      {!isNonMobileScreens && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          maxWidth="500px"
          minWidth="300px"
          style={{ backgroundColor: background }}
        >
          <Box display="flex" justifyContent="flex-end" p="1rem">
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close />
            </IconButton>
          </Box>

          <FlexBetween
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "3rem",
            }}
          >
            <IconButton onClick={() => dispatch(setMode())}>
              {theme.palette.mode === "dark" ? (
                <DarkMode style={{ fontSize: "25px" }} />
              ) : (
                <LightMode style={{ color: dark, fontSize: "25px" }} />
              )}
            </IconButton>
            {user && <Notifications userId={user._id} />}
            <Help style={{ fontSize: "25px" }} />
            <FormControl variant="standard">
              <Select
                value={username || ""}
                input={<InputBase />}
                displayEmpty
                renderValue={() => (
                  <Avatar
                    src={`data:image/png;base64,${user?.profilePhoto}`}
                    alt={user?.username}
                  />
                )}
              >
                {user ? (
                  <>
                    <MenuItem
                      value={username}
                      onClick={() => handleProfileNavigation(user?._id)}
                    >
                      <Avatar
                        src={`data:image/png;base64,${user?.profilePhoto}`}
                        alt={user?.username}
                        style={{ marginRight: "10px" }}
                      />
                      <Typography style={{ marginLeft: "10px" }}>
                        {username}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => navigate(`/update/${user._id}`)}>
                      Update Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate(`/update/${user._id}/password`)}
                    >
                      Update Password
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        signOut();
                      }}
                    >
                      Log out
                    </MenuItem>
                  </>
                ) : (
                  <MenuItem onClick={() => navigate(`/login`)}>Log in</MenuItem>
                )}
              </Select>
            </FormControl>
          </FlexBetween>
        </Box>
      )}
    </FlexBetween>
  );
};

export default NavBar;
