import { ClerkLoading, ClerkProvider, useSession } from "@clerk/clerk-react";
import React, { useEffect, useMemo } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import store from "./store/index";

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY; // safe to expose

async function patchFetchWithClerkToken(session) {
  const originalFetch = window.fetch;
  window.fetch = async (input, init = {}) => {
    if (input.includes(process.env.REACT_APP_BACKEND_URL)) {
      const token = await session.getToken();
      if (token) {
        init.headers = {
          ...init.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return originalFetch(input, init);
  };
}

const RootComponent = () => {
  const { isLoaded, isSignedIn, session } = useSession();

  useEffect(() => {
    if (isLoaded && isSignedIn && session) {
      patchFetchWithClerkToken(session);
    }
  }, [session, isLoaded, isSignedIn]);
  const ready = useMemo(
    () => isLoaded && (!isSignedIn || session),
    [isLoaded, isSignedIn, session],
  );
  return ready ? (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <App />
      </PersistGate>
    </Provider>
  ) : (
    <ClerkLoading />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
    <RootComponent />
  </ClerkProvider>,
);
