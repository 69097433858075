import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import "chart.js/auto";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { fetchIncomeData, fetchSpendingData } from "../../Api";
const SpendingChartWidget = ({
  userInfo,
  monthData,
  setMonthData,
  currentMonth,
  setCurrentMonth,
  lockMonthChanger = false,
  initialData = null,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDataForMonth(currentMonth);
  }, [currentMonth]);

  const fetchDataForMonth = async (month) => {
    if (initialData) {
      setMonthData(initialData);
      setLoading(false);
    } else {
      try {
        setLoading(true);
        const monthYear = dayjs(month, "MMMM YYYY", true);
        if (!monthYear.isValid()) {
          setError("Invalid date format");
          setLoading(false);
          return;
        }
        const year = monthYear.year();
        const monthFormatted = monthYear.format("MM"); // Get the month index (01-12)
        const spendingData = await fetchSpendingData(
          userInfo,
          year,
          monthFormatted,
        );
        const incomeData = await fetchIncomeData(
          userInfo,
          year,
          monthFormatted,
        );

        const remaining = incomeData.income - spendingData.totalExpenses;

        setMonthData({
          ...spendingData,
          income: incomeData.income,
          remaining,
        });
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }
  };

  const handlePreviousMonth = () => {
    const newMonth = dayjs(currentMonth, "MMMM YYYY")
      .subtract(1, "month")
      .format("MMMM YYYY");
    setCurrentMonth(newMonth);
    fetchDataForMonth(newMonth);
  };

  const handleNextMonth = () => {
    const newMonth = dayjs(currentMonth, "MMMM YYYY")
      .add(1, "month")
      .format("MMMM YYYY");
    if (dayjs(newMonth, "MMMM YYYY").isBefore(dayjs().add(1, "month"))) {
      setCurrentMonth(newMonth);
      fetchDataForMonth(newMonth);
    }
  };

  const chartData = {
    labels: monthData?.categories?.map((category) => category.name) || [],
    datasets: [
      {
        data: monthData?.categories?.map((category) => category.value) || [],
        backgroundColor:
          monthData?.categories?.map((category) => category.color) || [],
        borderColor: theme.palette.background.alt,
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.alt,
        borderRadius: 2,
        padding: 2,
        width: "100%",
        maxWidth: "500px",
        margin: "0 auto",
        color: theme.palette.text.primary,
        [theme.breakpoints.down("sm")]: {
          padding: 1,
          maxWidth: "100%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 1,
        }}
      >
        <Typography sx={{ fontWeight: "medium", fontSize: "1.25rem" }}>
          Spending
        </Typography>
        {!lockMonthChanger && (
          <Box>
            <IconButton onClick={handlePreviousMonth} size="small">
              <ArrowBackIosIcon />
            </IconButton>
            <Typography
              sx={{
                display: "inline-block",
                fontSize: "1rem",
                margin: "0 0.5rem",
              }}
            >
              {currentMonth}
            </Typography>
            <IconButton onClick={handleNextMonth} size="small">
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : monthData.totalExpenses === 0 ? (
        <Typography variant="h6" align="center" color="error">
          Your spending is $0
        </Typography>
      ) : (
        <>
          <Typography
            sx={{ fontSize: "0.875rem", color: theme.palette.text.secondary }}
          >
            {monthData.transactions} Transactions
          </Typography>
          <Box sx={{ position: "relative", marginTop: 2, height: "200px" }}>
            <Doughnut data={chartData} options={chartOptions} />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <Typography sx={{ fontWeight: "medium", fontSize: "1.5rem" }}>
                ${monthData.totalExpenses.toLocaleString()}
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: theme.palette.text.secondary,
                }}
              >
                Expenses
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 2,
              borderTop: `1px solid ${theme.palette.divider}`,
              paddingTop: 2,
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: theme.palette.success.main,
                  fontSize: "1.25rem",
                }}
              >
                ${monthData.income.toLocaleString()}
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: theme.palette.text.secondary,
                }}
              >
                Income
              </Typography>
            </Box>
            <Box
              sx={{
                width: "1px",
                backgroundColor: theme.palette.divider,
                margin: "0 16px",
              }}
            />
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontSize: "1.25rem",
                }}
              >
                ${monthData.totalExpenses.toLocaleString()}
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: theme.palette.text.secondary,
                }}
              >
                Expenses
              </Typography>
            </Box>
            <Box
              sx={{
                width: "1px",
                backgroundColor: theme.palette.divider,
                margin: "0 16px",
              }}
            />
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color:
                    monthData.remaining >= 0
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  fontSize: "1.25rem",
                }}
              >
                ${monthData.remaining.toLocaleString()}
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: theme.palette.text.secondary,
                }}
              >
                Remaining
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SpendingChartWidget;
