import React from "react";
import { Fab } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";

const PostCreationButton = ({ onClick }) => {
  return (
    <Fab
      color="primary"
      aria-label="create"
      onClick={onClick}
      size="large"
      sx={{
        position: "fixed",
        bottom: 26,
        right: 26,
        zIndex: 1300, // Ensure the button is above other content
        width: 60,
        height: 60,
      }}
    >
      <CreateIcon sx={{ fontSize: 30 }} />
    </Fab>
  );
};

export default PostCreationButton;
