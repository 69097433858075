import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useMatch } from "react-router-dom";
import { useAsync, useAsyncFn } from "react-use";
import {
  usePostBatch,
  usePostFilter,
  usePostLimit,
  usePostQuery,
} from "../utils/queryParamUtils";
import { useUser } from "./UserContext";

const FETCH_POSTS = "FETCH_POSTS";
const ADD_POST = "ADD_POST";
const UPDATE_POST = "UPDATE_POST";
const DELETE_POST = "DELETE_POST";
const FRESH_LOAD = "FRESH_LOAD";

const initialState = {
  posts: [],
  loading: false,
};

const postsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_POSTS:
      return {
        ...state,
        posts: [...state.posts, ...action.payload],
      };
    case FRESH_LOAD:
      return {
        ...state,
        posts: action.payload,
      };
    case ADD_POST:
      return {
        ...state,
        posts: [action.payload, ...state.posts],
      };
    case UPDATE_POST:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === action.payload._id ? action.payload : post,
        ),
      };
    case DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter((post) => post._id !== action.payload),
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const PostsContext = createContext(null);

export const PostsDispatchContext = createContext(null);

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/posts`;

export const PostsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(postsReducer, initialState);
  const { isLoaded: userLoaded, user } = useUser();
  const [filter] = usePostFilter();
  const [limit] = usePostLimit();
  const [batch, setBatch] = usePostBatch();
  const [query] = usePostQuery();
  const [hasMore, setHasMore] = useState(true);

  const match = useMatch("/profile/:userID");
  const userID = match ? match.params.userID : null;

  const url = useMemo(() => {
    if (userID) return `${baseUrl}/${userID}/posts`;
    if (query) return `${baseUrl}/search`;
    return `${baseUrl}/recommended`;
  }, [userID, query]);

  const defaultBatch = 1;

  const { loading } = useAsync(async () => {
    if (!userLoaded) return;
    const response = await fetch(
      `${url}?filter=${filter || ""}&limit=${limit || 10}&batch=${defaultBatch || 1}&query=${query || ""}`,
    );
    if (!response.ok) {
      throw new Error("Failed to fetch posts");
    }
    const data = await response.json();
    dispatch({ type: FRESH_LOAD, payload: data });
    setHasMore(data.length === Number(limit));
    return data;
  }, [filter, query, url, userLoaded, limit]);

  const [{ loading: loadingMore }, loadMore] = useAsyncFn(async () => {
    const newBatch = Number(batch) + 1;
    setBatch(newBatch);
    const response = await fetch(
      `${url}?filter=${filter || ""}&limit=${limit || 10}&batch=${newBatch}&query=${query || ""}`,
    );
    if (!response.ok) {
      throw new Error("Failed to load more posts");
    }
    const data = await response.json();
    dispatch({ type: FETCH_POSTS, payload: data });
    setHasMore(data.length > 0);
    return data;
  }, [filter, query, url, limit, batch, userLoaded]);

  const addPost = useCallback(
    async (newPost) => {
      const response = await fetch(`${baseUrl}/${user._id}/posts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPost),
      });
      const data = await response.json();
      dispatch({ type: ADD_POST, payload: data });
    },
    [user],
  );

  const updatePost = useCallback(async (id, updatedPost) => {
    const response = await fetch(`${baseUrl}/${id}/update`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedPost),
    });
    const data = await response.json();
    dispatch({ type: UPDATE_POST, payload: data });
  }, []);

  const deletePost = useCallback(async (id) => {
    await fetch(`${baseUrl}/${id}/delete`, {
      method: "DELETE",
    });
    dispatch({ type: DELETE_POST, payload: id });
  }, []);

  const hideOnPost = useCallback(async (id, hide) => {
    const response = await fetch(`${baseUrl}/${id}/hide`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ update: hide }),
    });
    const data = await response.json();
    dispatch({ type: UPDATE_POST, payload: data });
  }, []);

  const likePost = useCallback(async (id) => {
    const response = await fetch(`${baseUrl}/${id}/like`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    dispatch({ type: UPDATE_POST, payload: data });
  }, []);

  const commentPost = useCallback(async (id, comment) => {
    const response = await fetch(`${baseUrl}/${id}/comment`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comment),
    });
    const data = await response.json();
    dispatch({ type: UPDATE_POST, payload: data });
  }, []);

  const deleteComment = useCallback(async (id, commentID) => {
    const response = await fetch(`${baseUrl}/${id}/comment/${commentID}`, {
      method: "DELETE",
    });
    const data = await response.json();
    dispatch({ type: UPDATE_POST, payload: data });
  }, []);

  const stateWithLoading = useMemo(
    () => ({
      ...state,
      loading: loading && userLoaded,
      loadingMore: loading && userLoaded && loadingMore,
      hasMore,
    }),
    [loading, userLoaded, hasMore, state, loadingMore],
  );

  return (
    <PostsContext.Provider value={stateWithLoading}>
      <PostsDispatchContext.Provider
        value={{
          addPost,
          updatePost,
          hideOnPost,
          commentPost,
          deleteComment,
          likePost,
          deletePost,
          loadMore,
        }}
      >
        {children}
      </PostsDispatchContext.Provider>
    </PostsContext.Provider>
  );
};

export const usePosts = () => useContext(PostsContext);
export const usePostsDispatch = () => useContext(PostsDispatchContext);
