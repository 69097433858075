import PersonIcon from "@mui/icons-material/Person";
import PublicIcon from "@mui/icons-material/Public";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import VerifiedIcon from "@mui/icons-material/Verified";
import WorkIcon from "@mui/icons-material/Work"; // Updated icon
import {
  Avatar,
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { fetchUserData } from "../../Api";
import { useUser } from "../../context/UserContext";

const UserProfileHeaderWidget = ({ User }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [user, setUser] = useState(null);

  const { user: loggedInUser, retry } = useUser();
  const userId = User._id;

  const isCurrentUser = useMemo(() => {
    return loggedInUser?._id === userId;
  }, [loggedInUser, userId]);

  const navigate = useNavigate();

  const [state, followUser] = useAsyncFn(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/${loggedInUser._id}/friends/${userId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const data = await response.json();
    await retry();
    return data;
  }, [userId]);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const data = await fetchUserData(userId);
        setUser(data);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    getUserData();
  }, [userId]);

  if (!user) {
    return <Typography>Loading...</Typography>;
  }

  const tags = [
    { icon: <PersonIcon />, label: user.ageRange },
    { icon: <PublicIcon />, label: user.country },
    { icon: <WorkIcon />, label: user.profession }, // Updated icon
    { icon: <ShoppingCartIcon />, label: user.typeOfShopper },
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.alt,
        padding: isMobile ? 2 : 4,
        width: "100%",
        color: theme.palette.text.primary,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 1,
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{ width: 56, height: 56, marginRight: 2 }}
            src={`data:image/png;base64,${User.profilePhoto}`} // Render PNG from the base64 encoded string
            alt={user.username}
          />

          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontWeight: "medium-light",
                  fontSize: "1.25rem",
                  marginRight: 1,
                }}
              >
                {user.username || "Unknown"}
              </Typography>
              {user?.verified && (
                <VerifiedIcon
                  sx={{
                    color: theme.palette.success.main,
                    marginRight: 0.5,
                    fontSize: "1rem",
                  }}
                />
              )}
              <Typography
                sx={{
                  color: theme.palette.success.main,
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                ${user.userNetWorth ? user.userNetWorth.toLocaleString() : 0}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "1rem",
                color: theme.palette.text.primary,
                marginBottom: 0.5,
                display: "flex",
                alignItems: "center",
              }}
            >
              {user.followers ? user.followers.length.toLocaleString() : 0}{" "}
              Followers{" "}
              <Box
                component="span"
                sx={{ color: theme.palette.text.disabled, mx: 0.5 }}
              >
                |
              </Box>{" "}
              {user.following ? user.following.length.toLocaleString() : 0}{" "}
              Following
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: "0.875rem",
          color: theme.palette.text.secondary,
          marginBottom: 2,
          marginTop: 1.2,
          width: "100%",
          textAlign: "left",
          marginLeft: 1,
        }}
      >
        {user.userBio || "No bio available"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          width: "100%",
          marginBottom: 2,
        }}
      >
        {tags.length > 0
          ? tags.map((tag, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0.25rem 0.5rem",
                  borderRadius: 1,
                  backgroundColor: theme.palette.background.default,
                  fontSize: isMobile ? "0.75rem" : "0.80rem",
                }}
              >
                {tag.icon}
                <Typography
                  sx={{
                    marginLeft: 0.7,
                    fontSize: "inherit",
                    color: theme.palette.text.primary,
                  }}
                >
                  {tag.label}
                </Typography>
              </Box>
            ))
          : "No tags available"}
      </Box>
      {loggedInUser && (
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            padding: "0.5rem 1rem",
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.background.alt,
            },
            alignSelf: "flex-start",
          }}
          onClick={() => {
            if (isCurrentUser) {
              navigate(`/update/${userId}`);
            } else {
              followUser();
            }
          }}
        >
          {isCurrentUser
            ? "Edit Profile"
            : loggedInUser.friends.includes(userId)
              ? "Unfollow"
              : "Follow"}
        </Button>
      )}
    </Box>
  );
};

export default UserProfileHeaderWidget;
