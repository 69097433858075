import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
// path: client/src/assets/Ai-icon.png
import icon from "../../assets/Ai-icon.png";

const FactsWidget = () => {
  const theme = useTheme();

  const facts = [
    {
      icon: <FlightTakeoffIcon style={{ color: theme.palette.error.main }} />,
      text: "User's grocery spending is higher than",
      value: "84%",
      highlightColor: theme.palette.error.main,
    },
    {
      icon: <AttachMoneyIcon style={{ color: theme.palette.success.main }} />,
      text: "User's travel expenses are lower than",
      value: "11%",
      highlightColor: theme.palette.success.main,
    },
    {
      icon: <TrendingUpIcon style={{ color: theme.palette.success.main }} />,
      text: "User's debt is higher than",
      value: "61%",
      highlightColor: theme.palette.success.main,
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.alt,
        borderRadius: 2,
        padding: 3,
        width: "100%",
        color: theme.palette.text.primary,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <img
          src={icon}
          alt="icon"
          style={{ width: 24, height: 24, marginRight: 4 }}
        />
        <Typography
          style={{
            fontWeight: "medium bold",
            fontSize: "1.35rem",
            color: theme.palette.text.primary,
          }}
        >
          Facts
        </Typography>
        <Box
          sx={{
            backgroundColor: theme.palette.common.black,
            borderRadius: 1,
            padding: "0.25rem 0.5rem",
            marginLeft: 1,
          }}
        >
          <Typography
            variant="caption"
            sx={{ color: theme.palette.common.white, fontWeight: "bold" }}
          >
            Beta
          </Typography>
        </Box>
      </Box>
      {facts.map((fact, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: index < facts.length - 1 ? 2 : 0,
            filter: "blur(2px)", // Apply blur effect
          }}
        >
          <Box sx={{ marginRight: 2 }}>{fact.icon}</Box>
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontSize: "1rem",
            }}
          >
            {fact.text}{" "}
            <Typography
              component="span"
              sx={{
                fontWeight: "bold",
                color: fact.highlightColor,
              }}
            >
              {fact.value}
            </Typography>{" "}
            of the users.
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default FactsWidget;
