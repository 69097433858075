import {
  ArrowBack as ArrowBackIcon,
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  Verified as VerifiedIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { fetchSinglePost } from "../Api";
import { usePosts, usePostsDispatch } from "../context/PostsContext";
import { useUser } from "../context/UserContext";
import { timeAgo } from "../utils/timeUtils";
import Navbar from "./NavBar";
import UserImage from "./UI/UserImage";
import NetworthChartWidget from "./widgets/NetworthChartWidget";
import SalaryWidget from "./widgets/SalaryWidget";
import SpendingChartWidget from "./widgets/SpendingChartWidget";

const PostPage = () => {
  const { postId, postTitle } = useParams();
  const { user: loggedInUser } = useUser();
  const { commentPost, likePost } = usePostsDispatch();
  const { posts } = usePosts();
  const theme = useTheme();
  const navigate = useNavigate();

  const [currentMonth, setCurrentMonth] = useState(dayjs().format("MMMM YYYY"));
  const [monthData, setMonthData] = useState(null);
  const [comment, setComment] = useState("");
  const inputRef = useRef();
  const isMobile = useMediaQuery("(max-width:600px)");
  const skeletonWidth = isMobile ? "100%" : 800;
  const [postUser, setPostUser] = useState(null);
  const [post, setPost] = useState(null);

  const {
    loading,
    error,
    value: fetchedPost,
  } = useAsync(async () => {
    const data = await fetchSinglePost(postId);
    if (data.spendingData) {
      setMonthData(data.spendingData);
    }
    setPostUser(data.userId);
    setPost(data);
    return data;
  }, [postId]);

  const handleLike = async () => {
    if (!loggedInUser) {
      navigate("/login");
      return;
    } else if (!loggedInUser.onboarded) {
      navigate("/onboarding");
      return;
    }

    setPost((prevPost) => {
      const isLiked = Boolean(prevPost.likes[loggedInUser?._id]);
      const newLikes = { ...prevPost.likes };
      if (isLiked) {
        delete newLikes[loggedInUser._id];
      } else {
        newLikes[loggedInUser._id] = true;
      }
      return { ...prevPost, likes: newLikes };
    });

    try {
      await likePost(postId, loggedInUser._id);
    } catch (error) {
      setPost((prevPost) => {
        const isLiked = Boolean(prevPost.likes[loggedInUser._id]);
        const newLikes = { ...prevPost.likes };
        if (isLiked) {
          delete newLikes[loggedInUser._id];
        } else {
          newLikes[loggedInUser._id] = true;
        }
        return { ...prevPost, likes: newLikes };
      });
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!loggedInUser) {
      navigate("/login");
      return;
    } else if (!loggedInUser.onboarded) {
      navigate("/onboarding");
      return;
    }

    const newComment = createComment(loggedInUser, comment);

    setPost((prevPost) => ({
      ...prevPost,
      comments: [...prevPost.comments, newComment],
    }));

    setComment("");

    try {
      await commentPost(postId, newComment);
    } catch (error) {
      setPost((prevPost) => ({
        ...prevPost,
        comments: prevPost.comments.filter((c) => c !== newComment),
      }));
    }
  };

  const handleUserClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const handleCommentKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleCommentSubmit(e);
    }
  };

  const createComment = (user, commentText) => ({
    userId: user._id,
    name: user.username,
    image: user.profilePhoto,
    comment: commentText,
    createdAt: new Date().toISOString(),
  });

  if (loading) {
    return (
      <Box sx={{ padding: 4, maxWidth: "800px", margin: "0 auto" }}>
        {Array.from({ length: 6 }).map((_, idx) => (
          <Skeleton
            key={idx}
            variant="rectangular"
            width={skeletonWidth}
            height={idx === 2 ? 200 : 20}
            sx={{ marginBottom: 2 }}
          />
        ))}
      </Box>
    );
  }

  if (error) {
    return <Typography>Error fetching post data</Typography>;
  }

  if (!loading && !post) {
    return <Typography>No post found</Typography>;
  }

  const {
    title = "",
    description = "",
    createdAt = "",
    likes = {},
    comments = [],
    spendingData = null,
    netWorthData = [],
    salaryData = null,
    userId = {},
  } = post || {};

  const isLiked = Boolean(likes[loggedInUser?._id]);
  const likeCount = Object.keys(likes).length;

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "DiscussionForumPosting",
    headline: title,
    articleBody: description,
    datePublished: createdAt,
    author: {
      "@type": "Person",
      name: postUser?.username,
      image: postUser?.profilePhoto,
      url: `https://peerspocket.com/profile/${postUser?._id}`,
      sameAs: [
        "https://www.instagram.com/PeersPocket",
        "https://www.twitter.com/PeersPocket",
      ],
    },
    publisher: {
      "@type": "Organization",
      name: "Peers Pocket",
      logo: {
        "@type": "ImageObject",
        url: "https://peerspocket.com/path-to-your-logo.png",
      },
    },
    interactionStatistic: [
      {
        "@type": "InteractionCounter",
        interactionType: {
          "@type": "LikeAction",
        },
        userInteractionCount: likeCount,
      },
      {
        "@type": "InteractionCounter",
        interactionType: {
          "@type": "CommentAction",
        },
        userInteractionCount: comments.length,
      },
    ],
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": window.location.href,
    },
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={postUser?.profilePhoto} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={postUser?.profilePhoto} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Navbar />
      <IconButton
        onClick={() => navigate(-1)}
        sx={{ marginLeft: 3, marginTop: 3, size: "large" }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box sx={{ padding: 4, maxWidth: "800px", margin: "0 auto" }}>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
          <UserImage
            image={postUser?.profilePhoto}
            size="50px"
            onClick={() => handleUserClick(userId._id)}
            style={{ cursor: "pointer" }}
          />
          <Box sx={{ marginLeft: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                onClick={() => handleUserClick(userId._id)}
                sx={{ cursor: "pointer", marginRight: 2 }}
              >
                {postUser?.username}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.success.main,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {postUser?.verified && (
                  <VerifiedIcon sx={{ fontSize: "1rem", marginRight: 0.5 }} />
                )}
                {`$${
                  postUser.userNetWorth
                    ? postUser.userNetWorth.toLocaleString()
                    : 0
                }`}
              </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              {timeAgo(createdAt)}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontSize: "1.3rem",
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: description }}
          sx={{
            marginBottom: 2,
            color: theme.palette.text.secondary,
            fontSize: "1rem",
          }}
        />
        {spendingData && (
          <Box sx={{ marginTop: 2 }}>
            <SpendingChartWidget
              userInfo={userId}
              monthData={monthData}
              setMonthData={setMonthData}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              lockMonthChanger={true}
              initialData={monthData}
            />
          </Box>
        )}
        {netWorthData && netWorthData.length > 0 && (
          <Box sx={{ marginTop: 2 }}>
            <NetworthChartWidget
              userId={userId._id}
              initialData={netWorthData}
              lockTimeWindow
            />
          </Box>
        )}
        {salaryData && (
          <Box sx={{ marginTop: 2 }}>
            <SalaryWidget
              userIncome={salaryData.userIncome}
              percentile={salaryData.percentile}
              peerSalaries={salaryData.peerSalaries}
              country="Canada"
            />
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
          <IconButton onClick={handleLike}>
            {isLiked ? (
              <FavoriteOutlined color="error" />
            ) : (
              <FavoriteBorderOutlined />
            )}
          </IconButton>
          <Typography sx={{ marginLeft: 1 }}>{likeCount} Likes</Typography>
          <IconButton sx={{ marginLeft: 2 }} onClick={() => navigate("/login")}>
            <ChatBubbleOutlineOutlined />
          </IconButton>
          <Typography>{comments.length} Comments</Typography>
        </Box>
        <Divider sx={{ marginY: 2 }} />
        {comments.map((comment, index) => (
          <Box
            key={index}
            sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
          >
            <UserImage
              image={comment.image}
              size="40px"
              onClick={() => handleUserClick(comment.userId)}
              style={{ cursor: "pointer" }}
            />
            <Box sx={{ marginLeft: 2 }}>
              <Typography variant="body2">
                <strong
                  onClick={() => handleUserClick(comment.userId)}
                  style={{ cursor: "pointer" }}
                >
                  {comment.name}
                </strong>{" "}
                {comment.comment}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {timeAgo(comment.createdAt)}
              </Typography>
            </Box>
          </Box>
        ))}
        {loggedInUser && (
          <form onSubmit={handleCommentSubmit} style={{ marginTop: "16px" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <UserImage image={loggedInUser?.profilePhoto} size="40px" />
              <TextField
                fullWidth
                placeholder="Write a comment..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onKeyPress={handleCommentKeyPress}
                inputRef={inputRef}
                sx={{ marginLeft: 2 }}
              />
              <Button type="submit" variant="contained" sx={{ marginLeft: 2 }}>
                Post
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </>
  );
};

export default PostPage;
