import {
  AccountBalanceWallet,
  AttachMoney,
  Info,
  MonetizationOn,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  fetchIncomeData,
  fetchNetWorthHistory,
  fetchSpendingData,
} from "../../Api";
import { usePostsDispatch } from "../../context/PostsContext";
import { useUser } from "../../context/UserContext";
import NetworthChartWidget from "./NetworthChartWidget";
import SpendingChartWidget from "./SpendingChartWidget";

const PostUpdateWidget = ({ open, onClose, postData }) => {
  const theme = useTheme();
  const { updatePost } = usePostsDispatch();
  const [title, setTitle] = useState(postData ? postData.title : "");
  const [body, setBody] = useState(postData ? postData.description : "");
  const { user } = useUser();
  const [selectedWidget, setSelectedWidget] = useState(
    postData ? postData.selectedWidget : null,
  );
  const [currentMonth, setCurrentMonth] = useState(dayjs().format("MMMM YYYY"));
  const [netWorthData, setNetWorthData] = useState(
    postData ? postData.netWorthData : [],
  );
  const [monthData, setMonthData] = useState({
    transactions: 0,
    totalExpenses: 0,
    income: 0,
    remaining: 0,
    categories: [],
  });

  useEffect(() => {
    if (selectedWidget === "Spending") {
      const updateSpendingData = async () => {
        const monthYear = dayjs(currentMonth, "MMMM YYYY");
        const year = monthYear.year();
        const month = monthYear.format("MM");
        // Fetch spending and income data
        const spendingData = await fetchSpendingData(user._id, year, month);
        const incomeData = await fetchIncomeData(user._id, year, month);
        const remaining = incomeData.income - spendingData.totalExpenses;

        setMonthData({
          ...spendingData,
          income: incomeData.income,
          remaining,
        });
      };

      updateSpendingData();
    }
  }, [selectedWidget, currentMonth, user]);

  useEffect(() => {
    if (selectedWidget === "Networth") {
      const updateNetWorthData = async () => {
        const data = await fetchNetWorthHistory(user._id);
        setNetWorthData(data);
      };

      updateNetWorthData();
    }
  }, [selectedWidget, user]);

  const handlePost = async () => {
    const formData = {
      title,
      description: body,
      ...(selectedWidget === "Spending" && { spendingData: monthData }),
      ...(selectedWidget === "Networth" &&
        netWorthData &&
        netWorthData.length > 0 && { netWorthData }),
    };

    try {
      await updatePost(postData.postId, formData);
      onClose();
    } catch (error) {
      console.error("Error updating post:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6">Update Post</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          variant="outlined"
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2 }}
        />
        <ReactQuill
          theme="snow"
          value={body}
          onChange={setBody}
          modules={{
            toolbar: [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
            ],
          }}
          formats={[
            "header",
            "font",
            "list",
            "bullet",
            "bold",
            "italic",
            "underline",
            "color",
            "background",
            "align",
          ]}
          style={{ height: "200px", marginBottom: "20px" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            mt: 7.5,
            padding: "10px",
            borderRadius: 1,
            backgroundColor:
              theme.palette.mode === "dark" ? "#333333" : "#F0F0F0",
          }}
        >
          <IconButton onClick={() => setSelectedWidget("Networth")}>
            <MonetizationOn />
            <Typography variant="caption" sx={{ marginLeft: "5px" }}>
              Networth
            </Typography>
          </IconButton>
          <IconButton onClick={() => setSelectedWidget("Spending")}>
            <AccountBalanceWallet />
            <Typography variant="caption" sx={{ marginLeft: "5px" }}>
              Spending
            </Typography>
          </IconButton>
          <IconButton onClick={() => setSelectedWidget("Salary")}>
            <AttachMoney />
            <Typography variant="caption" sx={{ marginLeft: "5px" }}>
              Salary
            </Typography>
          </IconButton>
          <IconButton onClick={() => setSelectedWidget("Facts")}>
            <Info />
            <Typography variant="caption" sx={{ marginLeft: "5px" }}>
              Facts
            </Typography>
          </IconButton>
        </Box>

        {selectedWidget === "Spending" && (
          <Box sx={{ mt: 2 }}>
            <SpendingChartWidget
              userInfo={user}
              monthData={monthData}
              setMonthData={setMonthData}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
            />
            PostWidget
          </Box>
        )}
        {selectedWidget === "Networth" && (
          <Box sx={{ mt: 2 }}>
            <NetworthChartWidget
              userId={user._id}
              initialData={netWorthData}
              lockTimeWindow={true}
              setNetWorthData={setNetWorthData}
            />
          </Box>
        )}

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", margin: "1rem 0" }}
        >
          <Button
            variant="contained"
            onClick={handlePost}
            disabled={!title || !body}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PostUpdateWidget;
