import { Notifications as NotificationsIcon } from "@mui/icons-material";
import { Badge, Box, IconButton, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePosts } from "../../context/PostsContext";

const Notifications = () => {
  const [isTrayOpen, setIsTrayOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  // todo(qkhawaja): temporary solution, but we need to generate notifications for the user in the backend
  const { posts: value, loading } = usePosts();

  const notifications = [];

  if (value) {
    value.forEach((post) => {
      notifications.unshift({
        postId: post._id,
        message: `Your post "${post.title}" has been posted!`,
      });

      if (post.likeCount > 1) {
        notifications.unshift({
          postId: post._id,
          message: `Your post "${post.title}" has ${post.likeCount} likes!`,
        });
      }
    });
  }

  return (
    <Box sx={{ position: "relative" }}>
      <IconButton onClick={() => setIsTrayOpen(!isTrayOpen)}>
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsIcon style={{ fontSize: "25px" }} />
        </Badge>
      </IconButton>

      {isTrayOpen && (
        <Box
          sx={{
            position: "absolute",
            top: "40px",
            right: "0px",
            width: "300px",
            bgcolor: theme.palette.background.default,
            boxShadow: 3,
            zIndex: 100,
            borderRadius: "4px",
            padding: "10px",
          }}
        >
          {loading && <Typography>Loading...</Typography>}
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <Box
                key={index}
                onClick={() => {
                  navigate(`/post/${notification.postId}`);
                  setIsTrayOpen(false);
                }}
                sx={{
                  cursor: "pointer",
                  padding: "10px",
                  borderBottom: "1px solid #ddd",
                  backgroundColor:
                    index % 2 === 0
                      ? theme.palette.background.default
                      : theme.palette.background.alt,
                }}
              >
                <Typography>{notification.message}</Typography>
              </Box>
            ))
          ) : (
            <Typography>No new notifications</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Notifications;
