import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlightIcon from "@mui/icons-material/Flight";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

const getIcon = (categoryName) => {
  switch (categoryName) {
    case "Travel":
      return <FlightIcon />;
    case "Restaurant":
      return <RestaurantIcon />;
    case "Shopping":
      return <ShoppingBagIcon />;
    case "Coffee":
      return <LocalCafeIcon />;
    default:
      return null;
  }
};

const formatDate = (dateString) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

const SpendingByCategoryWidget = ({ categories = [], ownProfile }) => {
  const theme = useTheme();
  const [expandedCategory, setExpandedCategory] = useState(null);

  const handleExpandCategory = (index) => {
    if (expandedCategory === index) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(index);
    }
  };

  return (
    <Box
      sx={{
        borderRadius: 2,
        padding: 2,
        width: "100%",
      }}
    >
      {categories.length === 0 ? (
        <Typography>No spending data available for this period.</Typography>
      ) : (
        categories.map((category, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: 1.5,
              backgroundColor: theme.palette.background.alt,
              marginBottom: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1.25rem 1rem",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: category.color || "primary.main",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 2,
                  }}
                >
                  {getIcon(category.name)}
                </Box>
                <Typography sx={{ fontSize: "1rem" }}>
                  {category.name}
                </Typography>
              </Box>
              <Typography
                sx={{ fontSize: "1rem", marginLeft: "auto", marginRight: 2 }}
              >
                ${category.value ? category.value.toLocaleString() : "0"}
              </Typography>
              {ownProfile && (
                <IconButton
                  aria-label="expand"
                  size="small"
                  onClick={() => handleExpandCategory(index)}
                >
                  <ExpandMoreIcon />
                </IconButton>
              )}
            </Box>
            <Collapse
              in={ownProfile && expandedCategory === index}
              timeout="auto"
              unmountOnExit
            >
              <TableContainer>
                <Table aria-label="category details">
                  <TableHead>
                    <TableRow>
                      <TableCell>Note</TableCell>
                      <TableCell>Updated At</TableCell>
                      <TableCell align="right">Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {category.entries.map((entry, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{entry.note}</TableCell>
                        <TableCell>{formatDate(entry.updatedAt)}</TableCell>
                        <TableCell align="right">
                          ${entry.value ? entry.value.toLocaleString() : "0"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Box>
        ))
      )}
    </Box>
  );
};

export default SpendingByCategoryWidget;
