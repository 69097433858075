import { ClerkLoading } from "@clerk/clerk-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import LandingPage from "./components/LandingPage";
import LoginPage from "./components/LoginPage";
import OnboardingPage from "./components/OnboardingPage";
import PostPage from "./components/PostPage";
import ProfilePage from "./components/ProfilePage";
import RegisterPage from "./components/RegisterPage";
import SearchPostsPage from "./components/SearchPostsPage";
import UpdateProfile from "./components/UpdateProfile";
import { PostsProvider } from "./context/PostsContext";
import { UserProvider, useUser } from "./context/UserContext";
import { themeSettings } from "./theme";

function AuthenticatedRoute({ element, redirectTo }) {
  const { user, isLoaded } = useUser();
  if (!isLoaded) {
    return <ClerkLoading />;
  }

  const isAuth = Boolean(user);

  if (!isAuth) {
    return <Navigate to={redirectTo} />;
  }

  if (user && (user.onboarded === undefined || user.onboarded === false)) {
    return <Navigate to="/onboarding" />;
  }

  return element;
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/posts/post/:postId/:title?" element={<PostPage />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/onboarding" element={<OnboardingPage />} />
      <Route path="/home" element={<HomePage />} />
      <Route
        path="/profile/:userID"
        element={<ProfilePage ownProfile={false} isUserPage={false} />}
      />
      <Route
        path="/update/:userID"
        element={
          <AuthenticatedRoute element={<UpdateProfile />} redirectTo="/login" />
        }
      />
      <Route
        path="/update/:userID/password"
        element={
          <AuthenticatedRoute
            element={<UpdateProfile password />}
            redirectTo="/login"
          />
        }
      />
      <Route path="/posts/search" element={<SearchPostsPage />} />
    </Routes>
  );
};
function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <PostsProvider>
              <CssBaseline />
              <AppRoutes />
            </PostsProvider>
          </UserProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}
export default App;
